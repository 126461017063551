import React, { Component, Fragment } from 'react';
import ResponsiveXYFrame from "semiotic/lib/ResponsiveXYFrame";
import XYFrame from "semiotic/lib/XYFrame";
import { connect } from 'react-redux';
import dataProvider from '../dataProvider';
import { GET_MANY, GET_LIST, LinearProgress } from 'react-admin';
import Theme from './theme';
import Paper from '@material-ui/core/Paper';
import { max, min } from "d3-array";
import TabletOrAverage from './tabletTooltip';


class ForceWeightCorrelationChart extends Component{
  constructor(props) {
    super(props);
    this.state = {
      trialId: props.trialId[0],
      isDataPointsLoaded: false,
      dataPoints: [],
      colorIndex: new Set()
    }
    this.title = "Weight - Force (norm.) Correlation";
    this.backendUrl = "analyses/values-for-force-weight-correlation-chart";
    this.yAccessor = "force_average_force_in_kn";
    this.xAccessor = "weight_average_weight_in_mg";
    this.yAxis = "Force - Average force [kN]";
    this.xAxis = "Weight - Average weight [mg]";
    this.yValue = (d) => d.force_average_force_in_kn;
    this.xValue = (d) => d.weight_in_mg;
    this.xExtentAddition = (min, max) => {
      return [min + (min/10), max + (max/10)]
    }
    this.yExtentAddition = (min, max) => {
      return [min - 0.2, max + 0.2]
    }
    this.lineStyle = { strokeWidth: 0 }
    this.annotations = [{
      type: "y",
      force_average_force_in_kn: 0,
      note: {
        label: "",
        align: "left",
        lineType: null,
        wrap: 100
      },
      color: "#ffab91",
      dy: 0,
      dx: 0,
      connector: { end: "none" }
    },{
      type: "x",
      weight_average_weight_in_mg: 0,
      note: {
        label: "",
        align: "left",
        lineType: null,
        wrap: 100
      },
      color: "#ffab91",
      dy: 0,
      dx: 0,
      connector: { end: "none" }
    },

    ]
  }

  componentDidMount(){
    var { trialId } = this.state

    dataProvider.getList(this.backendUrl, {
      pagination: { page: 1, perPage: 1000 },
      sort: { field: 'tablet_number_sort', order: 'ASC' },
      filter: { trial_id: trialId }
    }).then(response => {
      const tablets = response.data;
      var colorIndex = new Set();
      tablets.forEach( (tablet) => {
        colorIndex.add(tablet.target_level_in_mpa)
      }
      );
      this.setState((previousState, props) => (
        {
          isDataPointsLoaded: true,
          colorIndex: [...colorIndex].sort((a,b) => (a-b)),
          dataPoints: previousState.dataPoints.concat(tablets)
        })
      )
    })
  }

  calculateXExtent(dataPoints){
    var maxValue = max(dataPoints.map(d => d[this.xAccessor]))
    var minValue = min(dataPoints.map(d => d[this.xAccessor]))
    if(this.xExtentAddition !== undefined) {
      var extent = this.xExtentAddition(minValue, maxValue)
      return extent
    }
  }

  calculateYExtent(dataPoints){
    var maxValue = max(dataPoints.map(d => d[this.yAccessor]))
    var minValue = min(dataPoints.map(d => d[this.yAccessor]))
    if(this.yExtentAddition !== undefined) {
      var extent = this.yExtentAddition(minValue, maxValue)
      return extent
    }
  }

  frameProps(dataPoints){

    return {
      /* --- SIZE --- */
      size: [500,400],
      responsiveWidth: true,

      /* --- Data --- */
      points: dataPoints,

      annotations: this.annotations,

      /* --- Size --- */
      margin: { top: 60, left: 80, right: 80, bottom: 60 },

      /* --- Process --- */
      xAccessor: this.xAccessor,
      yAccessor: this.yAccessor,
      yExtent: this.calculateYExtent(dataPoints),
      xExtent: this.calculateXExtent(dataPoints),

      /* --- Interact --- */
      hoverAnnotation: true,
      tooltipContent: d => {
        return <Paper className="tooltip-content">
          {TabletOrAverage(d, this.yAxis, this.yValue, this.xAxis, this.xValue)}
        </Paper>},
          /* --- Customize --- */

      showLinePoints: true,
      pointStyle: d => {
        const index = this.state.colorIndex.indexOf(d.target_level_in_mpa)
        return { fill: Theme[index], stroke: Theme[index], r: 4}
      },

      title: (
        <text fontSize={25} textAnchor="middle">
          {this.title}
        </text>
      ),
      lineStyle: this.lineStyle,
      axes: [
        { orient: "left", label: this.yAxis },
        { orient: "bottom", label: this.xAxis }
      ]
    }
  }

  render(){
    var { isDataPointsLoaded, dataPoints } = this.state
    if(isDataPointsLoaded) {
      return <div style={{marginTop: "20px"}}>
        <ResponsiveXYFrame {...this.frameProps(dataPoints)} />
      </div>
    }
    return <LinearProgress/ >
  }
}
export default connect(null, {})(ForceWeightCorrelationChart)
