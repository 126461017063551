import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import BondabilityChart from '../charts/bondabilityChart'
import CompressibilityChart from '../charts/compressibilityChart'
import CompactabilityChart from '../charts/compactabilityChart'
import { parse } from 'query-string';
import dataProvider from '../dataProvider';
import { RichTextField, BooleanField, TextField, ReferenceField, Title, GET_LIST, GET_MANY, LinearProgress } from 'react-admin'
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import BatchQuickPreviewButton from '../trials/BatchQuickPreviewButton';
import Divider from '@material-ui/core/Divider';
import Theme from './theme'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import Switch from '@material-ui/core/Switch';


class TrialsLegend extends Component {
  constructor(props){
    super(props);
    this.trialIds = props.trialIds;
    this.state = {
      trials: [],
      isLoaded: false
    }
  }

  componentDidMount(){
    dataProvider.getMany('trials', { ids: this.trialIds })
      .then(response => {
        this.setState({trials: response.data, isLoaded: true});
      })
  }

  colorOfTrial(trialId){
    var color = null
    this.trialIds.forEach((_id, index) => {
      if(_id === trialId) {
        color = Theme[index];
      }
    })
    return color
  }

  render(){
    var {isLoaded, trials} = this.state
    if(!isLoaded){
      return <LinearProgress/ >
    }
    return <Table>
      <TableHead>
        <TableRow>
          <TableCell>Trial</TableCell>
          <TableCell>Purpose</TableCell>
          <TableCell>Measurements?</TableCell>
          <TableCell>Batch</TableCell>
          <TableCell>Formulation Description</TableCell>
          <TableCell>Tooling</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        { trials.map((trial) => {
          return <TableRow key={trial.id}>
            <TableCell><Typography component="h2" ><Link to={{ pathname: `trials/${trial.id}/show` }} style={{  textDecoration: 'none' }}><font size="1.1em" color={this.colorOfTrial(trial.id)}>{trial.name} {trial.short_name ? '(' + trial.short_name + ')': ''}</font></Link></Typography></TableCell>
            <TableCell>
              <TextField record={trial} source="purpose" />
            </TableCell>
            <TableCell>
              <BooleanField label="Measurements?" record={trial} source="any_tablets_measured"/>
            </TableCell>
            <TableCell>
              <BatchQuickPreviewButton id={trial.batch_id} />
            </TableCell>
            <TableCell>
              <ReferenceField source="formulation_id" reference="formulations" record={trial} basePath="formulations" link={false}><RichTextField source="description" /></ReferenceField>
            </TableCell>
            <TableCell>
              <ReferenceField source="machine_id" reference="machines" record={trial} basePath="machines" link="show"><TextField source="name" /></ReferenceField><br />
              <ReferenceField source="punch_tip_id" reference="punch_tips" record={trial} basePath="punch_tips" link="show"><TextField source="name" /></ReferenceField><br />
              <ReferenceField source="punch_head_id" reference="punch_heads" record={trial} basePath="punch_heads" link="show"><TextField source="name" /></ReferenceField>
            </TableCell>
          </TableRow>
        }) }
      </TableBody>
    </Table>
  }
}

class ComparativeAnalysis extends Component {
  constructor(props) {
    super(props);
    const search = decodeURIComponent(props.location.search)
    this.state = {
      trialIds: Object.values(parse(search)),
      showAllValues: true,
      showTensileStrength: true,
      showSolidFraction: false,
    }
  }

  toggleShowSolidFraction = () => {
    this.setState(state => (
      {
        showSolidFraction: !state.showSolidFraction,
        newKey: Math.random(),
      }
    ));
  };

  toggleShowTensileStrength = () => {
    this.setState(state => (
      {
        showTensileStrength: !state.showTensileStrength,
        newKey: Math.random(),
      }
    ));
  };

  toggleShow = () => {
    this.setState(state => (
      {
        showAllValues: !state.showAllValues,
        newKey: Math.random(),
      }
    ));
  };

  render(){
    var { trialIds, showAllValues, showTensileStrength, showSolidFraction, newKey } = this.state

    return (
      <Fragment>
        <Title title="Comparative Analysis" />
        <Card>
          <CardContent>
            <TrialsLegend trialIds={trialIds} />
            <Grid container justify style={{margin: "20px"}}>
              <Grid container alignItems="center" item xs={4} justify="center">
                <Grid item><Typography variant="button">Average values only</Typography></Grid>
                <Grid item> <Switch checked={showAllValues} onChange={this.toggleShow} /> </Grid>
                <Grid item><Typography variant="button">All values</Typography></Grid>
              </Grid>
              <Grid container alignItems="center" item xs={4} justify="center">
                <Grid item><Typography variant="button">Crushing force</Typography></Grid>
                <Grid item> <Switch checked={showTensileStrength} onChange={this.toggleShowTensileStrength} /> </Grid>
                <Grid item><Typography variant="button">Tensile strength</Typography></Grid>
              </Grid>
              <Grid container alignItems="center" item xs={4} justify="center">
                <Grid item><Typography variant="button">Tablet Density</Typography></Grid>
                <Grid item> <Switch disabled checked={showSolidFraction} onChange={this.toggleShowSolidFraction} /> </Grid>
                <Grid item><Typography variant="button">Solid Fraction</Typography></Grid>
              </Grid>
            </Grid>
            <Divider />
            <Grid container spacing={2} style={{paddingBottom: "400px"}}>
              <Grid item xs={4} >
                <CompactabilityChart key={newKey} showAllValues={showAllValues} trialIds={trialIds} showTensileStrength={showTensileStrength} />
              </Grid>
              <Grid item xs={4} >
                <BondabilityChart key={newKey} showAllValues={showAllValues} trialIds={trialIds} showTensileStrength={showTensileStrength} showSolidFraction={showSolidFraction}/>
              </Grid>
              <Grid item xs={4} >
                <CompressibilityChart key={newKey} showAllValues={showAllValues} trialIds={trialIds} showSolidFraction={showSolidFraction}/>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Fragment>
    )
  }
}
export default ComparativeAnalysis;
