import React from 'react';
import { List, Datagrid, TextField, DateField, EditButton, NumberField } from 'react-admin';
import { Edit, SimpleForm, SelectInput, TextInput, NumberInput } from 'react-admin';
import { Create } from 'react-admin';
import { Show, TabbedShowLayout, ReferenceManyField, ReferenceField, Tab, ShowButton, required } from 'react-admin';
import Pagination from '../common/Pagination'

export const PunchHeadList = props => (
  <List {...props} pagination={<Pagination />} perPage={50}>
    <Datagrid rowClick="show">
      <TextField source="name" />
      <TextField label="Punch head" source="punch_head_type" />
      <TextField source="standard" />
      <NumberField source="length_of_flat_part_in_mm" />
      <EditButton />
    </Datagrid>
  </List>
);

export const PunchHeadEdit = props => (
  <Edit title={<PunchHeadTitle/>}{...props}>
    <SimpleForm redirect="show">
      <SelectInput label="Punch Head" source="punch_head_type" choices={[
        { id: 'D', name: 'D'},
        { id: 'B', name: 'B'}
      ]}/>
      <SelectInput source="standard" choices={[
        { id: 'EU', name: 'EU'},
        { id: 'TSM', name: 'TSM'},
        { id: 'Japan', name: 'Japan'},
        { id: 'Other', name: 'Other'}
      ]}/>
      <NumberInput source="length_of_flat_part_in_mm" validate={required()}/>
    </SimpleForm>
  </Edit>
);

export const PunchHeadCreate = props => (
  <Create {...props}>
    <SimpleForm redirect="show">
      <SelectInput label="Punch Head" source="punch_head_type" choices={[
        { id: 'D', name: 'D'},
        { id: 'B', name: 'B'}
      ]}/>
      <SelectInput source="standard" choices={[
        { id: 'EU', name: 'EU'},
        { id: 'TSM', name: 'TSM'},
        { id: 'Japan', name: 'Japan'},
        { id: 'Other', name: 'Other'}
      ]}/>
      <NumberInput source="length_of_flat_part_in_mm" validate={required()}/>
    </SimpleForm>
  </Create>
);

const PunchHeadTitle = ({record}) => {
  return <span>PunchHead - { record ? `${record.name}` : ''}</span>
}

export const PunchHeadShow = props => (
  <Show title={<PunchHeadTitle/>} {...props}>
    <TabbedShowLayout>
      <Tab label="Details">
        <TextField source="name" />
        <TextField label="Punch head" source="punch_head_type" />
        <TextField source="standard" />
        <NumberField source="length_of_flat_part_in_mm" />
        <DateField source="created_at" />
        <DateField source="updated_at" />
      </Tab>
      <Tab label="Trials">
        <ReferenceManyField
          addLabel={false}
          reference="trials"
          source="id"
          target="punch_head_id"
          sort={{ field: 'updated_at', order: 'DESC' }}
        >
          <Datagrid>
            <ReferenceField label="Name" source="id" reference="trials" link="show"><TextField source="name" /></ReferenceField>
            <DateField source="created_at" />
            <DateField source="updated_at" />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
    </TabbedShowLayout>
  </Show>
);
