import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import Download from '@material-ui/icons/CloudDownload';

class DownloadMeasurementButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      trial: props.record,
    }
  }
  render() {
    const { trial } = this.state;
    return (
      <Fragment>
        <div style={{padding: '1em'}}>
        <a href={process.env.REACT_APP_DATA_PROVIDER_URL  + '/trials/' + trial.id + '/measurement-download'} target="_blank" style={{textDecoration: 'none', color: 'inherit'}}>
          <Button color="primary">
            <Download />
            <span style={{marginLeft: '0.5em'}}>Export CSV</span>
          </Button>
        </a>
      </div>
      </Fragment>
    );
  }
}

export default connect(null, { })(DownloadMeasurementButton);
