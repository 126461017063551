import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_CHECK, AUTH_GET_PERMISSIONS } from 'react-admin';
import { Base64 } from 'js-base64';

export default (type, params) => {
  // called when the user attempts to log in
  if (type === AUTH_LOGIN) {
    const { username, password } = params;
    const request = new Request(process.env.REACT_APP_AUTH_PROVIDER_URL + "/authenticate", {
      method: 'POST',
      body: JSON.stringify({ username, password }),
      headers: new Headers({ 'Content-Type': 'application/json' }),
    })
    return fetch(request)
      .then(response => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(({ auth_token }) => {
        localStorage.setItem('username', username);
        localStorage.setItem('auth_token', auth_token);
      });
    return Promise.resolve();
  }
  // called when the user clicks on the logout button
  if (type === AUTH_LOGOUT) {
    localStorage.removeItem('username');
    localStorage.removeItem('auth_token');
    return Promise.resolve();
  }
  // called when the API returns an error
  if (type === AUTH_ERROR || type === AUTH_GET_PERMISSIONS) {
    const { status } = params;
    if (status === 401 || status === 403) {
      localStorage.removeItem('username');
      localStorage.removeItem('auth_token');
      return Promise.reject();
    }
    return Promise.resolve();
  }
  // called when the user navigates to a new location
  if (type === AUTH_CHECK) {
    const auth_token = localStorage.getItem('auth_token');
    if(auth_token != undefined){
      const auth_token_expires_in_seconds = parseInt(Base64.btoa(auth_token).match(/\{*."exp"\:(\d*)}/))
      const now_in_seconds = parseInt(new Date().getTime() / 1000)
      if(auth_token_expires_in_seconds - now_in_seconds < 3600){
        const request = new Request(process.env.REACT_APP_AUTH_PROVIDER_URL + '/extend-authentication-time', {
          method: 'POST',
          body: "",
          headers: new Headers({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${auth_token}`
          }),
        })
        return fetch(request)
          .then(response => {
            if (response.status < 200 || response.status >= 300) {
              localStorage.removeItem('username');
              localStorage.removeItem('auth_token');
              throw new Error(response.statusText);
            }
            return response.json();
          })
          .then(({ auth_token }) => {
            console.log("auth_token renewed");
            localStorage.setItem('auth_token', auth_token);
          });
      }
    }

    return localStorage.getItem('username')
      ? Promise.resolve()
      : Promise.reject();
  }
  return Promise.reject('Unknown method');
};
