import React from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useRefresh, useNotify, useRedirect, fetchStart, fetchEnd, Button } from 'react-admin';

const RemoveFileButton = ({ record, id}) => {
  const dispatch = useDispatch();
  const redirect = useRedirect();
  const notify = useNotify();
  const refresh = useRefresh();
  const [loading, setLoading] = useState(false);
  const icon = <DeleteIcon />;
  const tooltip = "Remove file"
  const handleClick = () => {
    setLoading(true);
    dispatch(fetchStart()); // start the global loading indicator
    fetch(`${process.env.REACT_APP_DATA_PROVIDER_URL}/trials/${id}/file/${record.id}/remove`, { method: 'DELETE', body: null, headers: { 'Authorization': 'Bearer ' + localStorage.getItem('auth_token') }})
      .then((response) => {
        if(!response.ok) throw response
        notify(`File removed`);
      })
      .catch((e) => {
        e.json().then( error => notify(error.message, 'warning'))
      })
      .finally(() => {
        refresh();
        setLoading(false);
        dispatch(fetchEnd()); // stop the global loading indicator
      });
  };
  return <Button onClick={handleClick} disabled={loading}>{icon}</Button>

}
export default RemoveFileButton;
