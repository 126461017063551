import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Timeline from '@material-ui/icons/Timeline';
import { Button } from 'react-admin';
import { Redirect, withRouter } from 'react-router-dom';
import { stringify } from 'query-string';

class BulkAnalyzeButton extends Component {
  state = {
    redirect: false
  }
  handleClick = () => {
    const { basePath, startUndoable, resource, selectedIds } = this.props;
    this.setState(() => ({
      redirect: true,
      selectedIds: selectedIds
    }))
  };

  render() {
    if (this.state.redirect === true) {

      return <Redirect to={{ pathname: '/comparative-analysis', search: encodeURIComponent(`${stringify(this.state.selectedIds)}`) }} />

    }
    return (
      <Button
        label="Show comparative Analysis Charts"
        onClick={this.handleClick}
      >
        <Timeline />
      </Button>
    );
  }
}

BulkAnalyzeButton.propTypes = {
  resource: PropTypes.string.isRequired,
  selectedIds: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default withRouter(BulkAnalyzeButton);
