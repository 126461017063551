import React, { Component, Fragment } from 'react';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import { NumberInput, SimpleForm, required, BooleanInput, SelectInput } from 'react-admin';
import { Form } from 'react-final-form'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { refreshView } from 'ra-core';
import { showNotification as showNotificationAction } from 'react-admin';
import { push as pushAction } from 'connected-react-router';
import Save from '@material-ui/icons/Save';
import Tooltip from '@material-ui/core/Tooltip';

class EditTabletDialog extends Component {

  constructor(props){
    super(props)
    this.state = {
      open: false,
      pristine: true,
      data: {}
    }
    this.handleInputChange = this.handleInputChange.bind(this)
  }

  handleInputChange(e) {
    if(Object.keys(this.state.data).length === 0) {
      this.setState({ data: this.props.record })
    }
    const target = e.target
    const name = target.name
    let pristine = ('' + this.props.record[name] === '' + target.value || '' + target.value === '') ? true : false 
    if(name === 'crushing_force_in_n') {
      pristine = ('' + this.props.record[name] === '' + target.value) ? true : false 
    }
    const value = name === 'capping' ? target.value : parseFloat(target.value)
    this.setState(prevState => ({
      data: {...prevState.data,
        [name]: value}, 
      pristine: pristine
    }))
  }

  handleOpen = (e) => {
    e.preventDefault();
    this.setState((prevState, props) => {
      return { open: true };
    });
  };

  handleClose = () => {
    this.setState((prevState, props) => {
      return { open: false };
    });
  };

  submit = () => {
    const { record, push, showNotification, refreshView } = this.props;
    const updatedRecord = this.state.data
    fetch(`${process.env.REACT_APP_DATA_PROVIDER_URL}/tablets/${record.id}`, { method: 'PUT', body: JSON.stringify(updatedRecord), headers: { 'Authorization': 'Bearer ' + localStorage.getItem('auth_token'), 'Content-Type': 'application/json' }})
    .then(() => {
        showNotification(`Tablet No. ${this.props.record.tablet_number} updated`);
        refreshView();
    })
    .catch((e) => {
        showNotification('Error: tablet not updated', 'warning')
    });
  }

  render() {
    return <Fragment>
      <Button color='primary' onClick={this.handleOpen} style={{display: 'inline-flex', alignItems: 'center', minHeight: '30px', paddingTop: '4px'}} >
        <Tooltip title="Edit Tablet" placement="left-start" arrow>
          <EditIcon />
        </Tooltip>
      </Button>
      <Dialog
        open={this.state.open}
        onClose={this.handleClose}
      >
        <DialogTitle id="approve-modal-title" onClose={this.handleClose}>Tablet No. {this.props.record.tablet_number}</DialogTitle>
        <Form 
          onSubmit={this.submit}
          render={({ handleSubmit }) => (
            <form onSubmit={ handleSubmit }>
              <SimpleForm toolbar={false} id={this.props.record.id} {...this.props}>
                <NumberInput label="Target Level [MPa]" source="target_level_in_mpa" onChange={this.handleInputChange} validate={required()} />
                {this.props.record.average_main_compaction_force_in_kn && 
                  <NumberInput label="Avg. MCF [kN]" source="average_main_compaction_force_in_kn" onChange={this.handleInputChange} validate={required()} />
                }
                {this.props.record.average_pre_compaction_force_in_kn && 
                  <NumberInput label="Avg. PCF [kN]" source="average_pre_compaction_force_in_kn" onChange={this.handleInputChange} validate={required()} />
                }
                {this.props.record.lower_punch_in_kn && 
                  <NumberInput label="LP [kN]" source="lower_punch_in_kn" onChange={this.handleInputChange} validate={required()} />
                }
                {this.props.record.upper_punch_in_kn && 
                  <NumberInput label="UP [kN]" source="upper_punch_in_kn" onChange={this.handleInputChange} validate={required()} />
                }
                <NumberInput label="Weight [mg]" source="weight_in_mg" onChange={this.handleInputChange} validate={required()} />
                <NumberInput label="Height [mm]" source="height_in_mm" onChange={this.handleInputChange} validate={required()} />
                {this.props.record.width_in_mm && 
                  <NumberInput label="Width [mm]" source="width_in_mm" onChange={this.handleInputChange} validate={required()} />
                }
                {this.props.record.length_in_mm && 
                  <NumberInput label="Length [mm]" source="length_in_mm" onChange={this.handleInputChange} validate={required()} />
                }
                {this.props.record.diameter_in_mm &&
                  <NumberInput label="Diameter [mm]" source="diameter_in_mm" onChange={this.handleInputChange} validate={required()} />
                }
                <NumberInput label="CF [N]" source="crushing_force_in_n" onChange={this.handleInputChange}/>
                <SelectInput source="capping" onChange={this.handleInputChange} validate={required()} choices={[
                  { id: 'no', name: 'no' },
                  { id: 'direct', name: 'direct' },
                  { id: 'cf', name: 'cf' }
                ]} />

              </SimpleForm>
              <DialogActions>
                <Button type="submit" color="primary" disabled={this.state.pristine}>
                  <Save /> 
                  <span style={{marginLeft: '0.5em'}}>Save</span>
                </Button>
                <Button onClick={this.handleClose}>Cancel</Button>
              </DialogActions>
            </form>
          )}
        />
      </Dialog>
    </Fragment>
  };
}

EditTabletDialog.propTypes = {
  push: PropTypes.func,
  record: PropTypes.object,
  showNotification: PropTypes.func,
  refreshView: PropTypes.func,
};

export default connect(null, {
  showNotification: showNotificationAction,
  refreshView: refreshView,
  push: pushAction,
})(EditTabletDialog);
