import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import Tooltip from '@material-ui/core/Tooltip';
import { useRefresh, useNotify, useRedirect, fetchStart, fetchEnd, Button } from 'react-admin';

const DisableTabletButton = ({ record = {} }) => {
  const dispatch = useDispatch();
  const redirect = useRedirect();
  const notify = useNotify();
  const refresh = useRefresh();
  const [loading, setLoading] = useState(false);
  const icon = record.is_disabled ? <SettingsBackupRestoreIcon />  : <CloseIcon />;
  const tooltip = record.is_disabled ? "Undisable tablet" : "Disable tablet"
  const handleClick = () => {
    setLoading(true);
    dispatch(fetchStart()); // start the global loading indicator
    const action = record.is_disabled ? "undisable" : "disable"
    const not = record.is_disabled ? "" : "NOT "
    //predictive setting
    record.is_disabled = !record.is_disabled
    fetch(`${process.env.REACT_APP_DATA_PROVIDER_URL}/tablets/${record.id}/${action}`, { method: 'PUT', body: null, headers: { 'Authorization': 'Bearer ' + localStorage.getItem('auth_token') }})
      .then((response) => {
        if(!response.ok) throw response
        notify(`Tablet is '${record.tablet_number}' now '${action}d'. Tablet will ${not}be considered for analysis charts and business rule exceptions.`);
      })
      .catch((e) => {
        e.json().then( error => notify(error.message, 'warning'))
      })
      .finally(() => {
        refresh();
        setLoading(false);
        dispatch(fetchEnd()); // stop the global loading indicator
      });
  };
  return <Button onClick={handleClick} disabled={loading}>
    <Tooltip title={tooltip} placement="left-start" arrow>
      {
        icon
      }
    </Tooltip>
  </Button>

}
export default DisableTabletButton;
