import React, { Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import RichTextInput from 'ra-input-rich-text';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Check from '@material-ui/icons/Check';
import Button from '@material-ui/core/Button';
import { Form, Field } from 'react-final-form'
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { refreshView } from 'ra-core';
import { showNotification as showNotificationAction } from 'react-admin';
import { push as pushAction } from 'connected-react-router';
import './ApproveModal.css';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

class ApproveModal extends Component {

  constructor(props){
    super(props)
    this.state = {
      open: false
    }
  }

  handleOpen = (event) => {
    event.preventDefault();

    this.setState((prevState, props) => {
      return { open: true };
    });
  };

  handleClose = () => {
    this.setState((prevState, props) => {
      return { open: false };
    });
  };

  approve = (values) => {
    const { record, push, showNotification, refreshView } = this.props;
    const updatedRecord = { trial: { approve: true, approval_comment: values.comment } };
    fetch(`${process.env.REACT_APP_DATA_PROVIDER_URL}/trials/${record.id}/approve`, { method: 'PUT', body: JSON.stringify(updatedRecord), headers: { 'Authorization': 'Bearer ' + localStorage.getItem('auth_token'), 'Content-Type': 'application/json' }})
      .then(response => {
        if(!response.ok) throw response
        showNotification('Trial approved');
        refreshView()
      })
      .catch((e) => {
        this.handleClose();
        console.log(e)
        e.json().then( error => showNotification(error.message, 'warning'))
      });
  }

  render() {
    return <div>
      <Button color='primary' onClick={this.handleOpen} style={{display: 'inline-flex', alignItems: 'center', minHeight: '30px', paddingTop: '4px'}} >
        <Check />
        <span style={{marginLeft: '0.5em'}}>Approve</span>
      </Button>
      <Dialog
        aria-labelledby="approve-modal-title"
        open={this.state.open}
        onClose={this.handleClose}
      >
        <DialogTitle id="approve-modal-title" onClose={this.handleClose}>Approval Dialog</DialogTitle>
        <Form onSubmit={this.approve}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <form onSubmit={handleSubmit}>
            <DialogContent>
              <DialogContentText>
                Please set a mandatory approval comment.
              </DialogContentText>
              <RichTextInput name="comment" placeholder="Approval Comment ..." />
            </DialogContent>
            <DialogActions>
              <Button color="primary" type="submit" disabled={submitting || pristine}>
                Approve
              </Button>
              <Button onClick={this.handleClose} color="secondary">
                Cancel
              </Button>
            </DialogActions>
          </form>
        )} />
      </Dialog>
    </div>
  };
}
ApproveModal.propTypes = {
  push: PropTypes.func,
  record: PropTypes.object,
  showNotification: PropTypes.func,
  refreshView: PropTypes.func,
};

export default connect(null, {
  showNotification: showNotificationAction,
  refreshView: refreshView,
  push: pushAction,
})(ApproveModal);
