import React, { Fragment } from 'react';
import IngredientQuantityInput from './IngredientQuantityInput'
import { NumberInput } from 'react-admin';

const QuantityCompositionInput = props => {
  return <Fragment>
    <NumberInput label="Total weight in [mg]" source="total_weight" initialValue="0" disabled/>
    <IngredientQuantityInput />
  </Fragment>
}

export default QuantityCompositionInput;
