import React from 'react';
import { Link } from 'react-router-dom';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import { withStyles } from '@material-ui/core/styles';
import { Button } from 'react-admin';

const styles = {
  button: {
    margin: '1em',
  }
};

const AddBatchButton = ({ classes, record }) => (
  <Button
    className={classes.button}
    component={Link}
    to={`/batches/create?formulation_id=${record.id}`}
    label="Add a batch"
    title="Add a batch"
  >
    <ChatBubbleIcon />
  </Button>
);

export default withStyles(styles)(AddBatchButton);
