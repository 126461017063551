// in src/comments/ApproveButton.js
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Check from '@material-ui/icons/Check';
import Undo from '@material-ui/icons/Undo';
import Button from '@material-ui/core/Button';
import { refreshView } from 'ra-core';
import { showNotification as showNotificationAction } from 'react-admin';
import { push as pushAction } from 'connected-react-router';
import ApproveModal from './ApproveModal';

class ApproveButton extends Component {
  withdraw_approval = (record, event) => {
    event.preventDefault();
    const { push, showNotification, refreshView } = this.props;
    fetch(`${process.env.REACT_APP_DATA_PROVIDER_URL}/trials/${record.id}/withdraw-approval`, { method: 'PUT', body: "", headers: { 'Authorization': 'Bearer ' + localStorage.getItem('auth_token') }})
      .then(response => {
        if(!response.ok) throw response
        showNotification('Trial approval withdrawn');
        refreshView()
      })
      .catch((e) => {
        console.log(e)
        e.json().then( error => showNotification(error.message, 'warning'))
      });
  }

  render() {
    const { record } = this.props;
    if (record && record.approved === true){
      return <Button color='primary' onClick={(event) => this.withdraw_approval(record, event)} style={{display: 'inline-flex', alignItems: 'center', minHeight: '30px', paddingTop: '4px'}} >
          <Undo />
          <span style={{marginLeft: '0.5em'}}>Withdraw Approval</span>
        </Button>
    }else{
      return <ApproveModal onClick={(event) => event.preventDefault} record={record} />
    }
  }
}

ApproveButton.propTypes = {
  push: PropTypes.func,
  record: PropTypes.object,
  showNotification: PropTypes.func,
  refreshView: PropTypes.func,
};

export default connect(null, {
  showNotification: showNotificationAction,
  refreshView: refreshView,
  push: pushAction,
})(ApproveButton);
