import React, { Component, Fragment } from 'react';
import BlurOn from '@material-ui/icons/BlurOn';
import BlurOff from '@material-ui/icons/BlurOff';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useUpdate } from 'react-admin';
import { refreshView } from 'ra-core';
import { showNotification as showNotificationAction } from 'react-admin';
import { push as pushAction } from 'connected-react-router';

class TogglePreblendButton extends Component {

  handleClick = () => {
    const { push, record, showNotification, refreshView } = this.props;
    console.log(record)
    const mark = (record && record.is_a_preblend) ? "unmark" : "mark"
    fetch(`${process.env.REACT_APP_DATA_PROVIDER_URL}/batches/${record.id}/toggle-preblend`, { method: 'PUT', body: '', headers: { 'Authorization': 'Bearer ' + localStorage.getItem('auth_token') }})
      .then(() => {
        showNotification(`Batch ${mark}ed as preblend`);
        push(`/batches/${record.id}/show`);
        refreshView()
      })
      .catch((e) => {
        console.error(e);
        showNotification('Error: Batch not marked/unmarked as preblend', 'warning')
      });
  }

  render(){
    const { record } = this.props;
    const mark = (record && record.is_a_preblend) ? "Unmark" : "Mark"
    return (
      <Button color='primary' onClick={this.handleClick} style={{display: 'inline-flex', alignItems: 'center', maxHeight: '29px', padding: '4px'}} >
        { (record && record.is_a_preblend) ? <BlurOff />  :  <BlurOn /> }
        <span style={{marginLeft: '0.5em'}}>{`${mark}`} as preblend</span>
      </Button>
    )
  }
}
TogglePreblendButton.propTypes = {
  push: PropTypes.func,
  record: PropTypes.object,
  showNotification: PropTypes.func,
  refreshView: PropTypes.func,
};

export default connect(null, {
  showNotification: showNotificationAction,
  refreshView: refreshView,
  push: pushAction,
})(TogglePreblendButton);
