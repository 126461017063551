import React from 'react';
import Chart from './chart'

const bondabilityChart = ({...props}) => {
  const showTensileStrength = (props.showTensileStrength == undefined) ? false : props.showTensileStrength
  const showSolidFraction = (props.showSolidFraction == undefined) ? false : props.showSolidFraction

  var yValueVariable = (d) => ((showTensileStrength) ? d.tensile_strength_in_mpa : d.crushing_force_in_n )
  var xValueVariable = (d) => ((showSolidFraction) ? d.solid_fraction : d.tablet_density_in_g_cm3) 
  var xAccessorVariable = (showSolidFraction) ? "solid_fraction" : "tablet_density_in_g_cm3"
  var yAccessorVariable = (showTensileStrength) ? "tensile_strength_in_mpa" : "crushing_force_in_n" 
  var xAxisName = (showSolidFraction) ? "Solid Fraction [-]" : "Tablet Density [g/cm³]"
  var yAxisName = (showTensileStrength) ? "Tensile Strength [MPa]" : "Crushing force [N]"

  return <Chart
    title = "Bondability"
    backendUrl = { `analyses/average-values-for-bondability-chart/with/${xAccessorVariable}/and/${yAccessorVariable}` }
    yAccessor = { yAccessorVariable }
    xAccessor = { xAccessorVariable }
    yAxis = { yAxisName }
    xAxis = { xAxisName }
    yValue = { yValueVariable } 
    xValue = { xValueVariable }
    annotations = { (showTensileStrength) ? [{
      type: "y",
      tensile_strength_in_mpa: 2,
      note: {
        label: "",
        align: "left",
        lineType: null,
        wrap: 100
      },
      color: "#ffab91",
      dy: 0,
      dx: 0,
      connector: { end: "none" }
    },] : []}
    yExtentAddition = {(min, max) => ([0, Math.ceil(max + 1)])}
    xExtentAddition = {(min, max) => ([(min/1.3), max + 0.2])}
    {...props}
  />
};

export default bondabilityChart;
