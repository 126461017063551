import React, { Component, Fragment } from 'react';
import ResponsiveXYFrame from "semiotic/lib/ResponsiveXYFrame";
import XYFrame from "semiotic/lib/XYFrame";
import { connect } from 'react-redux';
import dataProvider from '../dataProvider';
import { GET_MANY, GET_LIST, LinearProgress } from 'react-admin';
import Theme from './theme';
import Paper from '@material-ui/core/Paper';
import { max, min } from "d3-array";
import TabletOrAverage from './tabletTooltip';


class Chart extends Component{
  constructor(props) {
    super(props);
    this.state = {
      trialIds: props.trialIds,
      isDataPointsLoaded: false,
      isDataLineLoaded: false,
      dataPoints: [],
      dataLines: []
    }
    this.title = props.title;
    this.annotations = props.annotations;
    this.backendUrl = props.backendUrl;
    this.xAccessor = props.xAccessor;
    this.yAccessor = props.yAccessor;
    this.yAxis = props.yAxis;
    this.xAxis = props.xAxis;
    this.yValue = props.yValue;
    this.xValue = props.xValue;
    this.xExtentAddition = props.xExtentAddition;
    this.yExtentAddition = props.yExtentAddition;
    this.ticks = props.ticks
    this.differentiateCappedTablets = (props.differentiateCappedTablets == undefined) ? true : props.differentiateCappedTablets;
    this.showAllValues = (props.showAllValues == undefined) ? true : props.showAllValues;
    if(props.lineStyle == undefined){
      this.lineStyle = (d, i) => {
        return { stroke: Theme[d.title], strokeWidth: 3, fill: "none" }
      }
    }else{
      this.lineStyle = props.lineStyle
    }
  }

  componentDidMount(){
    this.loadData();
  }

  loadData(){
    var { trialIds } = this.state

    trialIds.forEach((trialId, index) => {
      dataProvider.getList('tablets', {
        pagination: { page: 1, perPage: 1000 },
        sort: { field: 'tablet_number_sort', order: 'ASC' },
        filter: { trial_id: trialId, is_disabled: 'false' }
      }).then(response => {
        this.setState((previousState, props) => {
          return {
            isDataPointsLoaded: true,
            dataPoints: previousState.dataPoints.concat(response.data)
          }}
        )
      }).then(
        dataProvider.getList(this.backendUrl, {
          pagination: { page: 1, perPage: 1000 },
          sort: { field: 'tablet_number_sort', order: 'ASC' },
          filter: { trial_id: trialId }
        }).then(response => {
          this.setState((previousState, props) => (
            {
              isDataLineLoaded: true,
              dataLines: previousState.dataLines.concat({ title: index, coordinates: response.data })
            })
          )
        })
      )
    })
  }

  calculateXExtent(dataPoints){
    var maxValue = max(dataPoints.map(d => d[this.xAccessor]))
    var minValue = min(dataPoints.map(d => d[this.xAccessor]))
    if(this.xExtentAddition !== undefined) {
      var extent = this.xExtentAddition(minValue, maxValue)
      return extent
    }
  }

  calculateYExtent(dataPoints){
    var maxValue = max(dataPoints.map(d => d[this.yAccessor]))
    var minValue = min(dataPoints.map(d => d[this.yAccessor]))
    if(this.yExtentAddition !== undefined) {
      var extent = this.yExtentAddition(minValue, maxValue)
      return extent
    }
  }

  frameProps(dataLines, dataPoints){
    var { trialIds } = this.state

    return {
      /* --- SIZE --- */
      size: [500,400],
      responsiveWidth: true,

      /* --- Data --- */
      lines: dataLines,
      points: (this.showAllValues) ? dataPoints : [],

      annotations: this.annotations,

      /* --- Size --- */
      margin: { top: 60, left: 80, right: 80, bottom: 60 },

      /* --- Process --- */
      xAccessor: this.xAccessor,
      yAccessor: this.yAccessor,
      yExtent: this.calculateYExtent(dataPoints),
      xExtent: this.calculateXExtent(dataPoints),

      /* --- Interact --- */
      hoverAnnotation: true,
      tooltipContent: d => {
        return <Paper className="tooltip-content">
          {TabletOrAverage(d, this.yAxis, this.yValue, this.xAxis, this.xValue)}
        </Paper>},
          /* --- Customize --- */

      showLinePoints: true,
      pointStyle: d => {
        if(d.is_average_tablet === true){
          if(d.has_capped_tablets == true){
            return { fill: "black", stroke: Theme[d.parentLine.title], r: 5, fillOpacity: 0.65}
          }else{
            return { fill: Theme[d.parentLine.title], r: 5, fillOpacity: 0.65}
          }
        }
        if(this.differentiateCappedTablets === true){
          if(d.capping == 'no'){
            return { stroke: Theme[trialIds.indexOf(d.trial_id)], strokeWidth: 1, fill: "gray", fillOpacity: 0.5 }
          }
          else{
            return { stroke: Theme[trialIds.indexOf(d.trial_id)], strokeWidth: 1, fill: "black"}
          }
        }else{
          return { stroke: Theme[trialIds.indexOf(d.trial_id)], strokeWidth: 1, fill: "gray", fillOpacity: 0.5 }
        }
      },

      title: (
        <text fontSize={25} textAnchor="middle">
          {this.title}
        </text>
      ),
      lineStyle: this.lineStyle,
      axes: [
        { orient: "left", label: this.yAxis },
        { orient: "bottom", label: this.xAxis, ticks: this.ticks}
      ]
    }
  }

  render(){
    var { isDataLineLoaded, isDataPointsLoaded, dataLines, dataPoints } = this.state
    if(isDataPointsLoaded && isDataLineLoaded) {
      return <div style={{marginTop: "20px"}}>
        <ResponsiveXYFrame {...this.frameProps(dataLines, dataPoints)} />
      </div>
    }
    return <LinearProgress/ >
  }
}
export default connect(null, {})(Chart)
