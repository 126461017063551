import React from 'react';
import { Field } from 'react-final-form';
import { ReferenceInput, AutocompleteInput } from 'react-admin';

import BatchQuickPreviewButton from './BatchQuickPreviewButton';

const BatchReferenceInput = props => {
  return (
  <div>
    <ReferenceInput {...props} perPage={1000}>
      <AutocompleteInput optionText={(record) => record && `${record.identification} - ${record.name}`} />
    </ReferenceInput>

    {/* We use Field to get the current value of the `batch_id` field */}
    <Field
      name="batch_id"
      component={
        ({ input }) =>
          input.value && <BatchQuickPreviewButton id={input.value} {...props}/>
      }
    />
  </div>
);
};

export default BatchReferenceInput;
