import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import dataProvider from '../dataProvider'
import { GET_LIST, LinearProgress, Link } from 'react-admin'
import { linkToRecord } from 'ra-core'
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';

const styles = { }

class TrialsSummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      isLoaded: false,
      total_count: 0
    }
  }

  componentDidMount(){
    dataProvider.getList('trials', {
      pagination: { page: 1, perPage: 5 },
      sort: { field: 'updated_at', order: 'DESC' },
    }).then(response => {
      this.setState({ isLoaded: true, items: response.data, total_count: response.total})
    });
  }

  render() {

    var { isLoaded, items, total_count } = this.state

    if(!isLoaded) {
      return <Card>
        <CardContent>
          <Typography color="textSecondary" variant="headline" component="h2">Last Trials Summary</Typography>
          <LinearProgress/ >
        </CardContent>
      </Card>
    }
    return (
      <Card>
        <CardContent>
          <Typography color="textSecondary" variant="headline" component="h2">Last Trials Summary</Typography>
          <Table>
            <TableBody>
              { items.map(item => (
                <TableRow key={item.id}>
                  <TableCell>
                    <Link to={`${linkToRecord("trials", item.id)}/show`}>{item.name} {item.short_name ? '(' + item.short_name + ')': ''}</Link>
                  </TableCell>
                  <TableCell>
                    <Typography color="textSecondary" variant="body1" component="small">
                      {item.purpose}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography color="textSecondary" variant="body1" component="small">
                      {item.customer_name}-{item.project_name}-{item.trial_serie_name}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography color="textSecondary" variant="body1" component="small">
                      {new Date(item.updated_at).toLocaleDateString()}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>
        <CardContent>
          <Typography color="textSecondary" align="right" >{total_count} trials in total count in database</Typography>
        </CardContent>
      </Card>
    )
  }
}

export default withStyles(styles)(TrialsSummary)
