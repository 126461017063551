import React from 'react'
import { LinearProgress } from 'react-admin'
import Typography from '@material-ui/core/Typography';

class CurrentAuthVersion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      api_version: <LinearProgress />
    }
  }
  componentDidMount(){

    fetch(`${process.env.REACT_APP_AUTH_PROVIDER_URL}/versions/current`, { method: 'GET', headers: { 'Authorization': 'Bearer ' + localStorage.getItem('auth_token') }})
      .then((response) => {
        response.json().then( o => {
          this.setState((state, props) => {
            return { api_version: o.current_version };
          })
        }
        )
      })
      .catch((e) => {
        console.error(e);
      });
  }
  render(){
    return <Typography color="textSecondary" variant="caption">
      Current Auth Version: {this.state.api_version} </Typography>
  }
}
export default CurrentAuthVersion;
