import React, { Component, Fragment } from 'react';
import ResponsiveXYFrame from "semiotic/lib/ResponsiveXYFrame";
import XYFrame from "semiotic/lib/XYFrame";
import { connect } from 'react-redux';
import dataProvider from '../dataProvider';
import { GET_MANY, GET_LIST, LinearProgress } from 'react-admin';
import Theme from './theme';
import Paper from '@material-ui/core/Paper';
import { max, min } from "d3-array";
import TabletOrAverage from './tabletTooltip';


class LevelChart extends Component{
  constructor(props) {
    super(props);
    this.state = {
      trialId: props.trialId,
      isDataLineLoaded: false,
      dataLines: []
    }
    this.title = props.title;
    this.annotations = props.annotations;
    this.xAccessor = props.xAccessor;
    this.yAccessor = props.yAccessor;
    this.yAxis = props.yAxis;
    this.xAxis = props.xAxis;
    this.yValue = props.yValue;
    this.xValue = props.xValue;
    this.xExtentAddition = props.xExtentAddition;
    this.yExtentAddition = props.yExtentAddition;
    if(props.lineStyle == undefined){
      this.lineStyle = (d, i) => {
        return { stroke: Theme[d.title], strokeWidth: 3, fill: "none" }
      }
    }else{
      this.lineStyle = props.lineStyle
    }
  }

  componentDidMount(){
    var { trialId } = this.state

    dataProvider.getList('tablets', {
      pagination: { page: 1, perPage: 1000 },
      sort: { field: 'tablet_number_sort', order: 'ASC' },
      filter: { trial_id: trialId, is_disabled: 'false' }
    }).then(response => {
      const tablets = response.data
      const levels = tablets.map(tablet => tablet.target_level_in_mpa)
      const distinctLevels = [...new Set(levels)]
      distinctLevels.forEach((level, index) => {
        this.setState((previousState, props) => (
          {
            dataLines: previousState.dataLines.concat({ title: index, coordinates: tablets.filter(tablet => tablet.target_level_in_mpa == level && tablet[this.xAccessor] > 0 && tablet[this.yAccessor] > 0) })
          }
        ))
      })

      this.setState((previousState, props) => (
        {
          isDataLineLoaded: true
        }
      ))
    });
  }

  calculateXExtent(dataLines){
    var maxValue = max(dataLines.map(d => max(d.coordinates.map(e => parseInt(e[this.xAccessor])))))
    var minValue = min(dataLines.map(d => min(d.coordinates.map(e => parseInt(e[this.xAccessor])))))
    if(this.xExtentAddition !== undefined) {
      var extent = this.xExtentAddition(minValue, maxValue)
      return extent
    } 
  }

  calculateYExtent(dataLines){
    var maxValue = max(dataLines.map(d => max(d.coordinates.map(e => e[this.yAccessor]))))
    var minValue = min(dataLines.map(d => min(d.coordinates.map(e => e[this.yAccessor]))))
    if(this.yExtentAddition !== undefined) {
      var extent = this.yExtentAddition(minValue, maxValue)
      return extent
    }
  }

  frameProps(dataLines){

    return {
      /* --- SIZE --- */
      size: [500,400],
      responsiveWidth: true,

      /* --- Data --- */
      lines: dataLines,

      annotations: this.annotations,

      /* --- Size --- */
      margin: { top: 60, left: 80, right: 80, bottom: 60 },

      /* --- Process --- */
      xAccessor: this.xAccessor,
      yAccessor: this.yAccessor,
      yExtent: this.calculateYExtent(dataLines),
      xExtent: this.calculateXExtent(dataLines),

      /* --- Interact --- */
      hoverAnnotation: true,
      tooltipContent: d => {
        return <Paper className="tooltip-content">
          {TabletOrAverage(d, this.yAxis, this.yValue, this.xAxis, this.xValue)}
        </Paper>},
          /* --- Customize --- */

      showLinePoints: true,
      pointStyle: d => {
        if(d.is_average_tablet == true){
          return { fill: "#e94e1b", r: 5 }
        }
        if(d.capping == 'no'){
          return { stroke: Theme[d.parentLine.title], r: 4, fill: "gray", fillOpacity: 0.5}
        }
        else{
          return { stroke: Theme[d.parentLine.title], r: 4, fill: "black"}
        }
      },

      title: (
        <text fontSize={25} textAnchor="middle">
          {this.title}
        </text>
      ),
      lineStyle: this.lineStyle,
      axes: [
        { orient: "left", label: { name: this.yAxis, locationDistance: 55 } },
        { orient: "bottom", label: this.xAxis }
      ]
    }
  }

  render(){
    var { isDataLineLoaded, dataLines } = this.state
    if(isDataLineLoaded) {
      return <div style={{marginTop: "20px"}}>
        <ResponsiveXYFrame {...this.frameProps(dataLines)} />
      </div>
    }
    return <LinearProgress/ >
  }
}
export default connect(null, {})(LevelChart)
