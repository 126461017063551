import React, { useCallback, Component, Fragment } from 'react';
import Print from '@material-ui/icons/Print';
import { Button, showNotification, GET_ONE, GET_LIST, LinearProgress } from 'react-admin';

const DownloadWeighingLogButton = (props) => {
  const handleClick = useCallback(
    event => {
      window.open(process.env.REACT_APP_DATA_PROVIDER_URL  + '/batches/' + props.record.id + '/weighing-log', '_blank')
    }
  )

  return (
    <Button
      label="Weighing Log"
      onClick={handleClick}
    >
      <Print />
    </Button>
  );
}

export default DownloadWeighingLogButton;
