import React from 'react';
import { Create, Edit, SimpleForm, ReferenceInput, SelectInput, ListButton, NumberInput, required, Toolbar, SaveButton, BooleanInput, FormDataConsumer, TopToolbar, ShowButton, translate, useNotify, useRefresh, useRedirect } from 'react-admin';
import { parse } from 'query-string';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button'
import { refreshView } from 'ra-core';
import UpdateTabletButton from './UpdateTabletButton';

const TabletCreateToolbar = props => (
  <Toolbar {...props} >
    <SaveButton
      label="save and show"
      redirect={`/trials/${props.record.trial_id}/show/measurements`}
      submitOnEnter={true}
    />
    <SaveButton
      label="save and add"
      redirect={false}
      submitOnEnter={false}
      variant="flat"
    />
  </Toolbar>
);


export const TabletCreate = props => {
  const { trial_id: trial_id_string } = parse(props.location.search);
  const trial_id = trial_id_string ? parseInt(trial_id_string, 10) : '';
  // For the grid, own button component?
  // https://stackoverflow.com/questions/57524734/prefilling-a-create-record-with-render-button-cause-the-application-to-cras
  return (
    <Create title="Measure Tablet" {...props}>
      <SimpleForm
        //count all measurements prepopulate tablet_number maybe you can prepopulate the level too...
        initialValues={{ trial_id }}
        redirect="show"
        toolbar={<TabletCreateToolbar />}
      >
        <ReferenceInput label="Level" source="level_id" reference="levels" filter={{ trial_id: trial_id_string }} filterToQuery={searchText => ({ name: searchText })} sort={{ field: 'created_at', order: 'ASC' }} validate={required()}><SelectInput optionText="name" /></ReferenceInput>
        <NumberInput label="Nr" source="tablet_number" validate={required()}/>
        <NumberInput label="Force UP [kN]" source="upper_punch_in_kn" validate={required()}/>
        <NumberInput label="Force LP [kN]" source="lower_punch_in_kn" validate={required()}/>
        <NumberInput label="Weight [mg]" source="weight_in_mg" validate={required()}/>
        <NumberInput label="Height [mm]" source="height_in_mm" validate={required()}/>
        <NumberInput label="Length [mm]" source="length_in_mm" validate={required()}/>
        <NumberInput label="Width [mm]" source="width_in_mm" validate={required()}/>
        <NumberInput label="Fraction [N]" source="crushing_force_in_n" validate={required()}/>
        <BooleanInput source="capping" />
      </SimpleForm>
    </Create>
  );
}

const TabletEditToolbar = ({ onCancel, translate, ...props }) => (
     <Toolbar {...props}>
        <UpdateTabletButton type="submit"/>
        <SaveButton disabled={props.pristine}/>
        <Button onClick={onCancel}>Cancel</Button>
     </Toolbar>
  );

export const TabletEdit = ({ onCancel, ...props }) => {
  
  return (
    <Edit title={<TabletTitle />} {...props}>
      <SimpleForm toolbar={ <TabletEditToolbar onCancel={onCancel} />}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <NumberInput label="Avg. MCF [kN]" source="average_main_compaction_force_in_kn" validate={required()}/>
            <NumberInput label="Avg. PCF [kN]" source="average_pre_compaction_force_in_kn" validate={required()}/>
          </Grid>
          <Grid item xs={3}>
            <NumberInput label="Force UP [kN]" source="upper_punch_in_kn" validate={required()}/>
            <NumberInput label="Force LP [kN]" source="lower_punch_in_kn" validate={required()}/>
          </Grid>

          <Grid item xs={3}>
            <NumberInput label="Weight [mg]" source="weight_in_mg" validate={required()}/>
            <NumberInput label="Height [mm]" source="height_in_mm" validate={required()}/>
          </Grid>

          <Grid item xs={3}>
            <NumberInput label="Diameter [mm]" source="diameter_in_mm" validate={required()}/>
          </Grid>

          <Grid item xs={3}>
            <NumberInput label="Length [mm]" source="length_in_mm" validate={required()}/>
            <NumberInput label="Width [mm]" source="width_in_mm" validate={required()}/>
          </Grid>

          <Grid item xs={3}>
            <NumberInput label="Fraction [N]" source="crushing_force_in_n" validate={required()}/>
            <BooleanInput source="capping" />
          </Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  )
}

const TabletTitle = ({record}) => {
  return <span> | Tablet Nr. - { record ? `${record.tablet_number}` : ''}</span>
}

