import React from 'react'
import { LinearProgress } from 'react-admin'
import Typography from '@material-ui/core/Typography';
import packageJson from '../../package.json';

class CurrentViewVersion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      view_version: <LinearProgress />
    }
  }
  componentDidMount(){
    this.setState((state, props) => {
      var version =  process.env.REACT_APP_VERSION
      if(version == undefined) {
        version = packageJson.version
      }
      return { view_version: version };
    })
  }
  render(){
    return <Typography color="textSecondary" variant="caption">
      Current View Version: {this.state.view_version} </Typography>
  }
}
export default CurrentViewVersion;
