import React from 'react';
import { Admin, Resource } from 'react-admin';
import { Route } from 'react-router-dom';

import authProvider from './authProvider'
import dataProvider from './dataProvider'
import polyglotI18nProvider from 'ra-i18n-polyglot';
import englishMessages from 'ra-language-english';

import ProjectIcon from '@material-ui/icons/Book';
import UserIcon from '@material-ui/icons/Group';
import PunchHeadIcon from '@material-ui/icons/Gavel';
import PunchTipIcon from '@material-ui/icons/HourglassFull';
import TrialSeriesIcon from '@material-ui/icons/Toc';
import TrialIcon from '@material-ui/icons/AllInclusive';
import FormulationIcon from '@material-ui/icons/CreateNewFolder';
import ProducerIcon from '@material-ui/icons/LocationCity';
import SubstanceIcon from '@material-ui/icons/Whatshot';
import MachineIcon from '@material-ui/icons/Android';
import ProfileIcon from '@material-ui/icons/SelectAll';
import BatchIcon from '@material-ui/icons/Opacity';

import dashboard from './dashboard/dashboard'

import { ProjectList, ProjectEdit, ProjectCreate, ProjectShow } from './projects/projects';
import { BatchList, BatchEdit, BatchCreate, BatchShow } from './batches/batches';
import { CustomerList, CustomerEdit, CustomerCreate, CustomerShow } from './customers/customers';
import { TrialList, TrialEdit, TrialCreate, TrialShow } from './trials/trials';
import { ProfileList, ProfileEdit, ProfileCreate, ProfileShow } from './profiles/profiles';
import { FormulationList, FormulationEdit, FormulationCreate, FormulationShow } from './formulations/formulations';
import { TrialSeriesList, TrialSeriesEdit, TrialSeriesCreate, TrialSeriesShow } from './trialSeries/trialSeries';
import { SubstanceList, SubstanceEdit, SubstanceCreate, SubstanceShow } from './substances/substances';
import { ProducerList, ProducerEdit, ProducerCreate, ProducerShow } from './producers/producers';
import { MachineList, MachineShow } from './machines/machines';
import { PunchHeadList, PunchHeadEdit, PunchHeadCreate, PunchHeadShow } from './punchHeads/punchHeads';
import { PunchTipList, PunchTipEdit, PunchTipCreate, PunchTipShow } from './punchTips/punchTips';
import { TabletEdit, TabletCreate } from './tablets/tablets';
import LoginPage from './LoginPage';

import Theme from './theme.js';
import CustomLayout from './CustomLayout.js';

import ComparativeAnalysis from './charts/ComparativeAnalysis';

const i18nProvider = polyglotI18nProvider(locale =>
    englishMessages,
    'en', 
    {
      allowMissing: true
    }
  
);
const customRoutes = [
  <Route exact path="/comparative-analysis" render={ props => <ComparativeAnalysis {...props} /> } />
]

const App = () => (
  <Admin
    layout={CustomLayout}
    theme={Theme}
    loginPage={LoginPage}
    dashboard={dashboard}
    customRoutes={customRoutes}
    authProvider={authProvider}
    i18nProvider={i18nProvider}
    dataProvider={dataProvider}
  >

    <Resource name="versions" />
    <Resource icon={UserIcon} name="customers" list={CustomerList} edit={CustomerEdit} create={CustomerCreate} show={CustomerShow}/>
    <Resource icon={ProjectIcon} name="projects" list={ProjectList} edit={ProjectEdit} create={ProjectCreate} show={ProjectShow} />
    <Resource icon={TrialSeriesIcon} name="trial_series" list={TrialSeriesList} show={TrialSeriesShow} create={TrialSeriesCreate} edit={TrialSeriesEdit}/>
    <Resource icon={TrialIcon} name="trials" list={TrialList} create={TrialCreate} show={TrialShow} edit={TrialEdit} />
    <Resource icon={BatchIcon} name="batches" list={BatchList} create={BatchCreate} show={BatchShow} edit={BatchEdit} />
    <Resource name="batch_items" />
    <Resource name="levels" />
    <Resource name="tablets" create={TabletCreate} edit={TabletEdit} />
    <Resource name="trial_audit_logs" />
    <Resource name="substance_types" />
    <Resource name="ingredients" />
    <Resource icon={FormulationIcon} name="formulations" list={FormulationList} show={FormulationShow} create={FormulationCreate} edit={FormulationEdit}/>
    <Resource name="compositions" />
    <Resource name="trial_files" />
    <Resource name="batch_files" />
    <Resource icon={SubstanceIcon} name="substances" list={SubstanceList} edit={SubstanceEdit} show={SubstanceShow} create={SubstanceCreate} />
    <Resource icon={ProducerIcon} name="producers" list={ProducerList} edit={ProducerEdit} create={ProducerCreate} show={ProducerShow}/>
    <Resource icon={MachineIcon} name="machines" list={MachineList} show={MachineShow}/>
    <Resource icon={ProfileIcon} name="profiles" list={ProfileList} edit={/*ProfileEdit*/false} create={/*ProfileCreate*/false} show={ProfileShow}/>
    <Resource icon={PunchTipIcon} name="punch_tips" list={PunchTipList} edit={PunchTipEdit} create={PunchTipCreate} show={PunchTipShow}/>
    <Resource icon={PunchHeadIcon} name="punch_heads" list={PunchHeadList} edit={PunchHeadEdit} create={PunchHeadCreate} show={PunchHeadShow}/>

  </Admin>

);
export default App;
