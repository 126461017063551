import React, { Component, Fragment } from 'react';
import './tooltip.css'
import './annotations.css'
import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Theme from './theme'

const format = (number) => {
  return new Intl.NumberFormat("de-DE", { maximumFractionDigits: 3 }).format(number);
}
const TabletOrAverage = (tablet, yAxis, yValue, xAxis, xValue) => {
  if(tablet.is_average_tablet == true){
    return AverageTablet(tablet, yAxis, yValue, xAxis, xValue)
  }
  else{
    return Tablet(tablet, yAxis, xAxis)
  }

};

const TabletItem = (key, value) => {
  if(value != 0) {
    return <li>
      <Typography color="textSecondary" variant="body1"><b>{key}</b>: {value}</Typography>
    </li>
  }
}

const AverageTablet = (tablet, yAxis, yValue, xAxis, xValue) => {
  return(
    <Fragment>
      <CardContent>
        <Typography variant="caption" display="block" gutterBottom>{`${tablet.trial_name}`}</Typography>
        <Typography component="h2" variant="h5">Average</Typography>
        <Typography color="textSecondary" variant="body1">{`Target Level ${tablet.target_level_in_mpa} MPa`}</Typography>
        <ul>
          {TabletItem(yAxis, format(yValue(tablet)))}
          {TabletItem(xAxis, format(xValue(tablet)))}
        </ul>
      </CardContent>
    </Fragment>
  );
}

const Tablet = (tablet, yAxis, xAxis) => {
  return(
    <Fragment>
      <CardContent>
        <Typography variant="caption" display="block" gutterBottom>{`${tablet.trial_name}`}</Typography>
        <Typography component="h2" variant="h5">{`Tablet No. ${tablet.tablet_number}`}</Typography>
        <Typography color="textSecondary" variant="body1">{`Capping: ${tablet.capping}, Level: ${tablet.target_level_in_mpa} MPa`}</Typography>

        <ul>
          {TabletItem("UP [kN]", format(tablet.upper_punch_in_kn))}
          {TabletItem("LP [kN]", format(tablet.lower_punch_in_kn))}
          {TabletItem("Avg. MCF [kN]", format(tablet.average_main_compaction_force_in_kn))}
          {TabletItem("Avg. PCF [kN]", format(tablet.average_pre_compaction_force_in_kn))}
          {TabletItem("Weight [mg]", format(tablet.weight_in_mg))}
          {TabletItem("Height [mm]", format(tablet.height_in_mm))}
          {TabletItem("Length [mm]", format(tablet.length_in_mm))}
          {TabletItem("Width [mm]", format(tablet.width_in_mm))}
          {TabletItem("Diameter [mm]", format(tablet.diameter_in_mm))}
        </ul>
        <hr />
        <ul>
          {TabletItem("Avg. Compaction Pressure [MPa]", format(tablet.average_compaction_pressure_in_mpa))}
          {TabletItem("Total Volume [mm³]", format(tablet.total_volume_in_mm3))}
          {TabletItem("Tensile Strength [MPa]", format(tablet.tensile_strength_in_mpa))}
          {TabletItem("Tablet Density [g/cm³]", format(tablet.tablet_density_in_g_cm3))}
          {TabletItem("Solid Fraction [-]", format(tablet.solid_fraction))}
          {TabletItem("CF [N]", format(tablet.crushing_force_in_n))}
        </ul>
      </CardContent>
    </Fragment>
  );
}

export default TabletOrAverage;
