import React, { useState } from 'react';
import Chart from './chart'
import dataProvider from '../dataProvider';
import { LinearProgress } from 'react-admin';

const ForceCorrelationChart = ({...props}) => {
  const [loaded, setLoaded] = useState(false);
  const [machineType, setMachineType] = useState();
  dataProvider.getOne('trials', {id: props.trialIds[0]})
    .then(response => {
      const machine_id = response.data.machine_id;
      dataProvider.getOne('machines', {id: machine_id})
        .then(machine => {
          setMachineType(machine.data.machine_type)
          setLoaded(true)
        })
    })
  if (!loaded) return <LinearProgress />
  if (machineType != "StylCam") return ""
  return <Chart
    title = "Force correlation"
    backendUrl = "analyses/average-values-for-force-correlation-chart"
    yAccessor = "lower_punch_in_kn"
    xAccessor = "upper_punch_in_kn"
    yAxis = "Lower punch force [kN]"
    xAxis = "Upper punch force [kN]"
    yValue = {(d) => (d.lower_punch_in_kn)}
    xValue = {(d) => (d.upper_punch_in_kn)}
    yExtentAddition = {(min, max) => ([0, Math.ceil(max + 5)])}
    xExtentAddition = {(min, max) => ([0, Math.ceil(max + 5)])}
    differentiateCappedTablets = {false}
    lineStyle = {{ strokeWidth: 0 }}
    {...props}
  />
};

export default ForceCorrelationChart;
