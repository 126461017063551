import React, { useState } from 'react';
import {Fragment} from 'react';
import LevelChart from './levelChart'
import dataProvider from '../dataProvider';
import Grid from '@material-ui/core/Grid';
import { LinearProgress } from 'react-admin';

const genericLevelChart = (title, yAccessor, yAxis, yValue, showAnnotation, nominalValue, props) => {
  var annotations = false;
  if(showAnnotation === true){
    annotations = [{
      type: "y",
      [yAccessor]: nominalValue,
      note: {
        label: "nominal",
        align: "left",
        lineType: null,
        wrap: 200
      },
      color: "#ffab91",
      dy: -1,
      dx: 0,
      connector: { end: "none" }
    },]
  }

  return <LevelChart
    title = {title}
    yAccessor = {yAccessor}
    xAccessor = "tablet_number"
    yAxis = {yAxis}
    xAxis = "Tablet No."
    yValue = {yValue}
    xValue = {(d) => (d.tablet_number)}
    yExtentAddition = {(min, max) => { 
      const nominalValueMin = (nominalValue === null) ? min : nominalValue;
      const nominalValueMax = (nominalValue === null) ? max : nominalValue;
      const correctionFactor = (Math.max(max, nominalValueMax) -  Math.min(min, nominalValueMin)) / 6
      return [(Math.min(min, nominalValueMin) - correctionFactor), (Math.max(max, nominalValueMax) + correctionFactor)]
    }
    }
    xExtentAddition = {(min, max) => ([min - 1,max + 1])}
    annotations = {annotations}
    {...props}
  />
}

const RelevantLevelCharts = ({...props}) => {
  const [loaded, setLoaded] = useState(false);
  const [punchTipDiameter, setPunchTipDiameter] = useState();
  const [punchTipLength, setPunchTipLength] = useState();
  const [punchTipWidth, setPunchTipWidth] = useState();
  const [punchTipShape, setPunchTipShape] = useState();
  dataProvider.getOne('trials', {id: props.trialId
  }).then(response => {
    const punch_tip_id = response.data.punch_tip_id;
    dataProvider.getOne('punch_tips', {id: punch_tip_id}).then(punch_tip => {
      setPunchTipDiameter(punch_tip.data.diameter_in_mm)
      setPunchTipLength(punch_tip.data.length_in_mm)
      setPunchTipWidth(punch_tip.data.width_in_mm)
      setPunchTipShape(punch_tip.data.shape)
      setLoaded(true)
    })
  })

  const heightChart = genericLevelChart("Height", "height_in_mm", "Height [mm]", (d) => d.height_in_mm, false, null, props )

  if (!loaded) return <LinearProgress />
  if (punchTipShape != "Round"){
    return <Fragment>
      <Grid item xs={4}>
        {heightChart}
      </Grid>

      <Grid item xs={4}>
        {genericLevelChart("Width", "width_in_mm", "Width [mm]", (d) => d.width_in_mm, true, punchTipWidth, props )}
      </Grid>
      <Grid item xs={4}>
        {genericLevelChart("Length", "length_in_mm", "Length [mm]", (d) => d.length_in_mm, true, punchTipLength, props )}
      </Grid>
    </Fragment>
  }
  return <Fragment>
    <Grid item xs={4}>
      {heightChart}
    </Grid>
    <Grid item xs={4}>
      {genericLevelChart("Diameter", "diameter_in_mm", "Diameter [mm]", (d) => d.diameter_in_mm, true, punchTipDiameter, props )}
    </Grid>
  </Fragment>
};

export default RelevantLevelCharts;
