import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import Print from '@material-ui/icons/Print';
import { showNotification, GET_ONE, GET_LIST, LinearProgress } from 'react-admin';
import { push } from 'connected-react-router';
import sanitize from 'sanitize-filename'
import dataProvider from '../dataProvider'

class DownloadTrialLogButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      trial: props.record,
    }
  }
  render() {
    const { trial } = this.state;
    return (
      <Fragment>
        <div style={{padding: '1em'}}>
        <a href={process.env.REACT_APP_DATA_PROVIDER_URL  + '/trials/' + trial.id + '/executional-log'} target="_blank" style={{textDecoration: 'none', color: 'inherit'}}>
          <Button color="primary">
            <Print />
            <span style={{marginLeft: '0.5em'}}>Executional Log</span>
          </Button>
        </a>
      </div>
      </Fragment>
    );
  }
}

export default connect(null, { })(DownloadTrialLogButton);
