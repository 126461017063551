import React from 'react';
import { List, EditButton, BooleanInput, BooleanField, Edit, Create, SimpleForm, TextInput, NumberInput, required, Toolbar, TopToolbar, DeleteButton, SaveButton, Show, TabbedShowLayout, Tab, TextField, DateField, ReferenceField, ReferenceManyField, Datagrid, NumberField, Pagination, FunctionField } from 'react-admin';
import { RichTextField } from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { parse } from 'query-string';
import DownloadWeighingLogButton from './downloadWeighingLogButton'
import UploadFilesButton from './uploadFilesButton'
import RemoveFileButton from './RemoveFileButton'
import PDF from '@material-ui/icons/PictureAsPdf';
import File from '@material-ui/icons/PhotoLibrary';
import DownloadFileButton from './downloadFileButton'
import { withStyles } from '@material-ui/core';
import IngredientReferenceField from '../ingredients/IngredientReferenceField';
import TogglePreblendButton from './TogglePreblendButton';

const BatchShowActions = ({ basePath, data, resource, ...props }) => (
  <TopToolbar>
    <EditButton basePath={basePath} record={data} />
    <DownloadWeighingLogButton record={data} {...props} />
    <TogglePreblendButton record={data} {...props} />
    {/* Add your custom actions
    <Button color="primary" onClick={customAction}>Custom Action</Button>*/}
  </TopToolbar>
);

const BatchTitle = ({record}) => {
  return <span>{ record ? `${record.formulation_name} - Batch` : ''}</span>
}

export const BatchList = props => (
  <List {...props} hasCreate={false} sort={{ field: 'identification', order: 'DESC'}}>
    <Datagrid rowClick="show">
      <TextField source="identification" />
      <ReferenceField source="formulation_id" reference="formulations" link="show">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="name" />
    </Datagrid>
  </List>
);

export const BatchShow  = props => (
  <Show title={<BatchTitle/>} actions={<BatchShowActions {...props} />} {...props}>
    <TabbedShowLayout style={{ paddingBottom: '16px'}}>
      <Tab label="Batch">
        <ReferenceField source="formulation_id" reference="formulations" link="show"><TextField source="name" /></ReferenceField>
        <TextField source="identification" />
        <TextField source="special_feature" />
        <BooleanField source="is_a_preblend" />
        <TextField source="batch_size_in_g" />
        <RichTextField source="guidelines" />
        <ReferenceManyField
          addLabel={false}
          reference="batch_items"
          source="id"
          target="batch_id"
          sort={{ field: 'sort_field', order: 'ASC' }}
        >
          <Datagrid>
            <IngredientReferenceField label="Ingredient" batch_item={props.record} />
            <NumberField label="Quantity of [g]" source="quantity_of_in_g" />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
      <Tab label="Files" path="files">
        <UploadFilesButton />
        <ReferenceManyField
          addLabel={false}
          reference="batch_files"
          target="batch_id"
          sort={{ field: 'created_at', order: 'DESC' }}
          pagination={<Pagination />}
        >
          <Datagrid>
            <TextField source="filename" sortable={false}/>
            <FunctionField label="Content type" style={{color: "#999"}} render={record => {
              switch(record.content_type) {
                case 'application/pdf':
                  return <PDF />
              default:
                  return <File />
              }
            }
            }
          />
          <FunctionField label="File size" render={record => `${record.byte_size/1000} kB`} />
          <DateField source="created_at" />
          <DownloadFileButton {...props} />
          <RemoveFileButton {...props} />
        </Datagrid>
      </ReferenceManyField>
    </Tab>
    <Tab label="Used in Trials" path="used-in-trials">
      <ReferenceManyField
        addLabel={false}
        reference="trials"
        source="id"
        target="batch_id"
        sort={{ field: 'updated_at', order: 'DESC' }}
      >
        <Datagrid>
          <ReferenceField label="Name" source="id" reference="trials" link="show"><TextField source="name" /></ReferenceField>
          <ReferenceField label="Trial Serie" source="trial_serie_id" reference="trial_series" link="show"><TextField source="name" /></ReferenceField>
          <ReferenceField label="Project" source="project_id" reference="projects" link="show"><TextField source="name" /></ReferenceField>
          <DateField source="created_at" />
          <DateField source="updated_at" />
        </Datagrid>
      </ReferenceManyField>
    </Tab>
  </TabbedShowLayout>
</Show>
);

const toolbarStyles = {
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};
const BatchEditToolbar = withStyles(toolbarStyles)(props => (
  <Toolbar {...props} >
    <SaveButton />
    <DeleteButton
      redirect={`/formulations/${props.record.formulation_id}/show/batches`}
    />
  </Toolbar>
));

export const BatchEdit = props => {
  return (
    <Edit title="Edit Batch" {...props}>
      <SimpleForm
        redirect="show"
        toolbar={<BatchEditToolbar />}
      >
        <NumberInput source="batch_size_in_g" validate={required()} />
        <TextInput source="identification" disabled />
        <TextInput source="special_feature"  />
        <RichTextInput source="guidelines" />
      </SimpleForm>
    </Edit>
  );
};

const redirect = (basePath, id, data) => `/formulations/${data.formulation_id}/show/batches`


export const BatchCreate = props => {
  const { formulation_id: formulation_id } = parse(props.location.search);
  console.log(props.record)
  // For the grid, own button component?
  // https://stackoverflow.com/questions/57524734/prefilling-a-create-record-with-render-button-cause-the-application-to-cras
  return (
    <Create title="Create Batch" {...props}>
      <SimpleForm
        initialValues={{ formulation_id }}
        redirect={redirect}
      >
        <NumberInput source="batch_size_in_g" validate={required()} />
        <TextInput source="special_feature"  />
        <BooleanInput label="use as PreBlend" source="is_a_preblend"/>
        <RichTextInput source="guidelines" />
      </SimpleForm>
    </Create>
  );
}


