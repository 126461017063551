import { fetchUtils } from 'react-admin';
import simpleRestProvider from 'ra-data-simple-rest';

const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }
  const auth_token = localStorage.getItem('auth_token');
  options.headers.set('Authorization', `Bearer ${auth_token}`);
  return fetchUtils.fetchJson(url, options);
}

const dataProvider = simpleRestProvider(process.env.REACT_APP_DATA_PROVIDER_URL, httpClient);

const myDataProvider = {
  ...dataProvider,
  create: (resource, params) => {
    if (resource === 'substances') {
      const file = params.data.security_data_sheet
      if(file && file.rawFile instanceof File){
        return Promise.resolve(convertFileToBase64(file))
          .then(base64File => ({
            src: base64File,
            title: `${file.title}`
          }))
          .then(transformedNewFile => dataProvider.create(resource, {
            ...params,
            data: {
              ...params.data,
              security_data_sheet: transformedNewFile
            }
          }));
      }
    }
    // for other request types and resources, fall back to the default request handler
    return dataProvider.create(resource, params);
  },
  update: (resource, params) => {
    if (resource === 'substances') {
      const file = params.data.security_data_sheet
      if(file && file.rawFile instanceof File){
        return Promise.resolve(convertFileToBase64(file))
          .then(base64File => ({
            src: base64File,
            title: `${file.title}`
          }))
          .then(transformedNewFile => dataProvider.update(resource, {
            ...params,
            data: {
              ...params.data,
              security_data_sheet: transformedNewFile
            }
          }));
      }
    }
    // for other request types and resources, fall back to the default request handler
    return dataProvider.update(resource, params);
  },
};

/**
 * Convert a `File` object returned by the upload input into a base 64 string.
 * That's not the most optimized way to store images in production, but it's
 * enough to illustrate the idea of data provider decoration.
 */
const convertFileToBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file.rawFile);

    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

export default myDataProvider;
