import React from 'react';
import { Field, useForm } from 'react-final-form';
import { ReferenceInput, SelectInput, FormDataConsumer, REDUX_FORM_NAME } from 'react-admin';
import { AutocompleteInput, ArrayInput, SimpleFormIterator, NumberInput, required } from 'react-admin';
import IngredientQuickPreviewButton from './IngredientQuickPreviewButton';
import './formulation.css';

const IngredientNameField = (record) => `${record.brandname}, [${(record.substance_types[0] ? record.substance_types[0].name : '')}]` ;

const IngredientFormButton = ({ formData, scopedFormData, getSource, ...rest }) => {
  const form = useForm();
  return (
    <Field
      validate={required()}
      name={getSource("ingredient.ingredient_id")}
      component={
        ({ input }) => {
          return (
            input.value && <IngredientQuickPreviewButton ingredient_id={input.value} {...rest} />
          );
        }
      }
    />
  )
}

const PercentCalculatorCalculator = ({ formData, scopedFormData, getSource, ...rest }) => {
  const form = useForm();

  return(
    <NumberInput label="Portion of in [%]" source={getSource("ingredient.portion_of")} step={0.01} validate={required()} fullWidth={true} onChange={(event) =>{
      if(scopedFormData && scopedFormData.ingredient){
        var remaining_percent = formData.composition.reduce((percent_remaining,item) => {
          //console.log("-")
          if(item.ingredient){
            var itemValue = parseFloat(item.ingredient.portion_of)
            if(isNaN(itemValue)){
              itemValue = 0
            }
          }
          //console.log("  percent_remaining: " + percent_remaining)
          //console.log("  item Value: " + itemValue)
          return percent_remaining + itemValue
        }, 0)
        //console.log("remaining_percent: " + remaining_percent)

        var newValue = parseFloat(event.target.value)
        if(isNaN(newValue)){
          newValue = 0
        }

        if(newValue == ""){
          newValue = scopedFormData.ingredient.portion_of * -1
        }

        var previousValue = scopedFormData.ingredient.portion_of
        if(isNaN(previousValue)){
          previousValue = 0
        }

        var remainingPercentValue = remaining_percent
        if(isNaN(remainingPercentValue)){
          remainingPercentValue = 0
        }

        if(event.target.value == ""){
          previousValue=0
        }

        //console.log("newValue: " + newValue)
        //console.log("previousValue: " + previousValue)
        //console.log("remainingPercentValue: " + remainingPercentValue)
        //console.log("=======================================")

        form.change('remaining_percent', (100 - (remainingPercentValue - previousValue + newValue)).toFixed(4))
      }
    }} />
  )
}

const IngredientQualityInput = props => {
  return (
    <div>
      <ArrayInput label="Composition" source="composition" resource="formulation">
        <SimpleFormIterator>
          <ReferenceInput formClassName="substance" fullWidth={true} label="Ingredient" reference="ingredients" resource="ingredients" source="ingredient.ingredient_id"  basePath="/ingredients" sort={{ field: 'name', order: 'ASC' }} >
            <AutocompleteInput optionText="search_name" matchSuggestion={(filter, choice) => true }/>
          </ReferenceInput>
          {/* We use Field to get the current value of the `formulation_id` field */}
          <FormDataConsumer>
            {formDataProps => {
              return <IngredientFormButton {...formDataProps}/>
            }}
          </FormDataConsumer>
          <FormDataConsumer formClassName="quantity">
            {formDataProps => {
              return <PercentCalculatorCalculator {...formDataProps} />
            }}
          </FormDataConsumer>
        </SimpleFormIterator>
      </ArrayInput>

    </div>
  );
};

export default IngredientQualityInput;
