import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { List, Datagrid, RichTextField, TextField, DateField, ReferenceField, BooleanField, EditButton, Filter, NumberField, DeleteButton } from 'react-admin';
import { Loading, Edit, SimpleForm, SelectInput, TextInput, ReferenceInput, DateInput, BooleanInput, NumberInput, required } from 'react-admin';
import { Create, AutocompleteInput, ArrayInput, SimpleFormIterator } from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { ShowController, ShowView, TabbedShowLayout, Tab, ShowButton, ArrayField, ReferenceManyField, FunctionField, TopToolbar, CloneButton } from 'react-admin';
import { useGetOne } from 'react-admin';
import IngredientReferenceField from '../ingredients/IngredientReferenceField'

import CompositionInput from './CompositionInput'
import AddBatchButton from './AddBatchButton'
import Pagination from '../common/Pagination'

export const FormulationList = props => (
  <List {...props} pagination={<Pagination />} perPage={50}>
    <Datagrid rowClick="show">
      <TextField source="name" />
      <NumberField label="Total weight [mg]" source="total_weight" />
      <DateField source="created_at" />
      <DateField source="updated_at" />
    </Datagrid>
  </List>
);

const FormulationTitle = ({record}) => {
  return <span>Formulation - { record ? `${record.name}` : ''}</span>
}

const cardActionStyle = {
  zIndex: 2,
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'flex-end',
  flexWrap: 'wrap',
  padding: 0,
};

const FormulationShowActions = ({basePath, data, resource}) => {
  return(
    <TopToolbar style={cardActionStyle}>
      <EditButton basePath={basePath} record={data} />
      <CloneButton basePath={basePath} record={data} resource={resource} />
    </TopToolbar>
  );
};

export const FormulationShow = props => {

  return(
    <ShowController {...props}>
      { controllerProps =>
        <ShowView title={<FormulationTitle />} actions={<FormulationShowActions />} {...props} {...controllerProps}>
          <TabbedShowLayout style={{ paddingBottom: '16px'}}>
            <Tab label="Composition">
              <TextField source="name" />
              <RichTextField source="description" />
              {
                controllerProps.record && controllerProps.record.formulation_type == "Formulating::QuantityComposition" && <NumberField label="Total weight [mg]" source="total_weight" />
              }
              <ReferenceManyField
                addLabel={false}
                reference="compositions"
                source="id"
                target="formulation_id"
                sort={{ field: 'portion_of', order: 'DESC' }}
              >
                <Datagrid>
                  <IngredientReferenceField label="Ingredient" batch_item={props.record} />
                  <TextField label="Type" source="ingredient_types" />
                  <NumberField label="Portion of [%]" source="portion_of" />
                  <NumberField label="Quantity of [mg]" source="quantity_of" />
                </Datagrid>
              </ReferenceManyField>
            </Tab>
            <Tab label="Batches" path="batches">
              <ReferenceManyField
                addLabel={false}
                reference="batches"
                source="id"
                target="formulation_id"
                sort={{ field: 'created_at', order: 'DESC' }}
              >
                <Datagrid>
                  <ReferenceField label="Identification" source="id" reference="batches" link="show"><TextField source="identification" /></ReferenceField>
                  <ReferenceField label="Name" source="id" reference="batches" link="show"><TextField source="name" /></ReferenceField>
                </Datagrid>
              </ReferenceManyField>
              <AddBatchButton />
            </Tab>
            <Tab label="Used in Trials" path="used-in-trials">
              <ReferenceManyField
                addLabel={false}
                reference="trials"
                source="id"
                target="formulation_id"
                sort={{ field: 'updated_at', order: 'DESC' }}
              >
                <Datagrid>
                  <ReferenceField label="Name" source="id" reference="trials" link="show"><TextField source="name" /></ReferenceField>
                  <ReferenceField label="Trial Serie" source="trial_serie_id" reference="trial_series" link="show"><TextField source="name" /></ReferenceField>
                  <ReferenceField label="Project" source="project_id" reference="projects" link="show"><TextField source="name" /></ReferenceField>
                  <DateField source="created_at" />
                  <DateField source="updated_at" />
                </Datagrid>
              </ReferenceManyField>

            </Tab>
          </TabbedShowLayout>
        </ShowView>
      }
    </ShowController>
  )};

export const FormulationCreate = props => {
  return(
    <Create {...props}>
      <SimpleForm redirect="show" >
        <TextInput label="Name" source="name" validate={required()} />
        <RichTextInput label="Description" source="description" />
        <CompositionInput />
      </SimpleForm>
    </Create>
  )};

export const FormulationEdit = props => {
  return(
    <Edit {...props}>
      <SimpleForm redirect="show" >
        <TextInput label="Name" source="name" validate={required()} />
        <RichTextInput label="Description" source="description" />
        <NumberInput label="Total weight in [mg]" source="total_weight"  disabled/>
        <CompositionInput />
      </SimpleForm>
    </Edit>
  )};
