import React from 'react';
import { List, Datagrid, SelectField, TextField, DateField, ReferenceField, EditButton, NumberField, FunctionField } from 'react-admin';
import { Edit, SimpleForm, SelectInput, TextInput, NumberInput } from 'react-admin';
import { Create, ReferenceInput } from 'react-admin';
import Chip from '@material-ui/core/Chip';
import { Show, TabbedShowLayout, ReferenceManyField, Tab, ShowButton, required } from 'react-admin';
import Pagination from '../common/Pagination'

const MachinesField = ({ record }) => {
  if(record && record.machines){
    return record.machines.map(item => (
      <Chip key={item.id} label={item.name} />
    ))
  }else{
    return ""
  }
}
MachinesField.defaultProps = {
    addLabel: true
};

export const ProfileList = props => (
  <List {...props} pagination={<Pagination />} perPage={50}>
    <Datagrid rowClick="show">
      <TextField source="name" />
      <MachinesField label="Machines" />
      <TextField source="profile_type" />
      <DateField source="created_at" />
      <DateField source="updated_at" />
    </Datagrid>
  </List>
);

const ProfileTypes = [
  { id: 'NO_SIM', name: 'No sim' },
  { id: 'DWELL_TIME', name: 'Dwell Time' },
  { id: 'RTP', name: 'RTP' },
]

const MachineSpeedUnitChoices = [
  { id: 'rounds_per_minute', name: 'rpm' },
  { id: 'tablets_per_minute', name: 'Tab./Min.' },
  { id: 'percent', name: '%' },
  { id: 'milliseconds', name: 'ms' },
]

export const ProfileEdit = props => (
  <Edit title={<ProfileTitle/>}{...props}>
    <SimpleForm redirect="show">
      <ReferenceInput source="machine_type" reference="machines" validate={required()}><SelectInput optionText="name" /></ReferenceInput>
      <SelectInput source="profile_type" choices={ProfileTypes} validate={required()}/>
      <TextInput source="name" validate={required()} />
      <NumberInput source="pitch_circle_diameter_in_mm"/>
      <NumberInput source="precomp_diameter_in_mm" />
      <NumberInput source="maincomp_diameter_in_mm" />
      <NumberInput source="max_speed" validate={required()}/>
      <SelectInput source="max_speed_unit" choices={MachineSpeedUnitChoices} />
      <NumberInput source="max_simulated_speed_in_rpm" />
    </SimpleForm>
  </Edit>
);

export const ProfileCreate = props => (
  <Create {...props}>
    <SimpleForm redirect="show">
      <ReferenceInput source="machine_type" reference="machines" validate={required()}><SelectInput optionText="name" /></ReferenceInput>
      <SelectInput source="profile_type" choices={ProfileTypes} validate={required()}/>
      <TextInput source="name" validate={required()} />
      <NumberInput source="pitch_circle_diameter_in_mm" />
      <NumberInput source="precomp_diameter_in_mm" />
      <NumberInput source="maincomp_diameter_in_mm" />
      <NumberInput source="max_speed" validate={required()}/>
      <SelectInput source="max_speed_unit" choices={MachineSpeedUnitChoices} />
      <NumberInput source="max_simulated_speed_in_rpm" />
    </SimpleForm>
  </Create>
);

const ProfileTitle = ({record}) => {
  return <span>Profile - { record ? `${record.name}` : ''}</span>
}


const MachineSpeedUnit = (unit) => {
  const item = MachineSpeedUnitChoices.find(item => item.id === unit)
  if(item != undefined) return item.name;
  return "Unit cannot resolved"
}

export const ProfileShow = props => (
  <Show title={<ProfileTitle/>} {...props}>
    <TabbedShowLayout>
      <Tab label="Details">
        <MachinesField label="Machines"/>
        <TextField source="name" />
        <NumberField source="pitch_circle_diameter_in_mm" />
        <NumberField source="precomp_diameter_in_mm" />
        <NumberField source="maincomp_diameter_in_mm" />
        <FunctionField source="max_speed" render={record => `${record.max_speed} ${MachineSpeedUnit(record.max_speed_unit)}`} />
        <NumberField source="max_simulated_speed_in_rpm" />
        <DateField source="created_at" />
        <DateField source="updated_at" />
      </Tab>
      <Tab label="Trials">
        <ReferenceManyField
          addLabel={false}
          reference="trials"
          source="id"
          target="profile_id"
          sort={{ field: 'updated_at', order: 'DESC' }}
        >
          <Datagrid>
            <ReferenceField label="Name" source="id" reference="trials" link="show"><TextField source="name" /></ReferenceField>
            <DateField source="created_at" />
            <DateField source="updated_at" />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
    </TabbedShowLayout>
  </Show>
);
