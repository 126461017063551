import React from 'react';
import { List, Filter, AutocompleteInput, Datagrid, TextField, DateField, ReferenceField, EditButton } from 'react-admin';
import { Edit, SimpleForm, SelectInput, TextInput, ReferenceInput, DateInput } from 'react-admin';
import { Create } from 'react-admin';
import { Show, TabbedShowLayout, ReferenceManyField, Tab, ShowButton, required } from 'react-admin';
import Pagination from '../common/Pagination'

const ProjectFilter = props => (
  <Filter {...props}>
    <ReferenceInput label="Project name" source="id" reference="projects" filterToQuery={searchText => ({ name: searchText })}  sort={{ field: 'name', order: 'ASC' }}><AutocompleteInput optionText="name" /></ReferenceInput>
  </Filter>
);

export const ProjectList = props => (
  <List filters={<ProjectFilter />} {...props} pagination={<Pagination />} perPage={50}>
        <Datagrid rowClick="show">
            <TextField source="name" />
            <ReferenceField source="customer_id" reference="customers">
              <TextField source="name" />
            </ReferenceField>
            <DateField source="created_at" />
            <DateField source="updated_at" />
            <EditButton />
        </Datagrid>
    </List>
);

const ProjectTitle = ({record}) => {
  return <span>Project - { record ? `${record.name}` : ''}</span>
}


export const ProjectEdit = props => (
  <Edit title={<ProjectTitle/>} {...props}>
        <SimpleForm>
            <TextInput source="name" validate={required("Name is required")}/>
            <ReferenceInput source="customer_id" reference="customers"><SelectInput optionText="name" disabled={true}/></ReferenceInput>
        </SimpleForm>
    </Edit>
);

export const ProjectCreate = props => (
    <Create {...props}>
        <SimpleForm redirect="show" >
            <TextInput source="name" validate={required("Name is required")}/>
            <ReferenceInput source="customer_id" reference="customers" validate={required("Customer is required")}><SelectInput optionText="name" /></ReferenceInput>
        </SimpleForm>
    </Create>
);


export const ProjectShow = props => (
    <Show title={<ProjectTitle/>} {...props}>
    <TabbedShowLayout>
      <Tab label="Details">
            <TextField source="name" />
            <ReferenceField source="customer_id" reference="customers" link="show"><TextField source="name" /></ReferenceField>
              <DateField source="created_at" />
              <DateField source="updated_at" />
      </Tab>
      <Tab label="Trial series">
        <ReferenceManyField
          addLabel={false}
          reference="trial_series"
          source="id"
          target="project_id"
          sort={{ field: 'updated_at', order: 'DESC' }}
        >
          <Datagrid>
            <ReferenceField label="Name" source="id" reference="trial_series" link="show"><TextField source="name" /></ReferenceField>
            <DateField source="created_at" />
            <DateField source="updated_at" />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
    </TabbedShowLayout>
    </Show>
);
