import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Save from '@material-ui/icons/Save';
import { showNotification } from 'react-admin'
import { connect } from 'react-redux';
import { refreshView } from 'ra-core';

class UpdateTabletButton extends Component {
    
    handleSubmit = () => {
        const { record, showNotification, refreshView } = this.props;
        console.log( {...record} )
        const updatedRecord = { ...record };
        fetch(`${process.env.REACT_APP_DATA_PROVIDER_URL}/tablets/${record.id}`, { method: 'PUT', body: JSON.stringify(updatedRecord), headers: { 'Authorization': 'Bearer ' + localStorage.getItem('auth_token'), 'Content-Type': 'application/json' }})
            .then(() => {
                showNotification('Tablet Updated');
                refreshView();
            })
            .catch((e) => {
                showNotification('Error: comment not approved', 'warning')
            });
    }

    render() {
        return <Button type="submit" color="primary" disabled={this.props.pristine}>
            <Save /> 
            <span style={{marginLeft: '0.5em'}}>Save</span>
        </Button>;
    }
}

UpdateTabletButton.propTypes = {
    record: PropTypes.object,
    showNotification: PropTypes.func,
    refreshView: PropTypes.func,
};

export default connect(null, {
        showNotification,
        refreshView,
    })(UpdateTabletButton);