import React, { Fragment, useRef } from 'react';
import { List, Datagrid, TextField, RichTextField, DateField, EditButton, NumberField, FormDataConsumer } from 'react-admin';
import { Edit, SimpleForm, SelectInput, TextInput, ReferenceInput, DateInput } from 'react-admin';
import { Create } from 'react-admin';
import { Show, TabbedShowLayout, ReferenceManyField, ReferenceField, Tab, ShowButton, required, NumberInput } from 'react-admin';
import Grid from '@material-ui/core/Grid';
import RichTextInput from 'ra-input-rich-text';
import InputAdornment from '@material-ui/core/InputAdornment';
import { FormSpy, useForm } from 'react-final-form'
import Pagination from '../common/Pagination'

export const PunchTipList = props => (
  <List {...props} pagination={<Pagination />} perPage={50}>
    <Datagrid rowClick="show">
      <TextField source="name" />
      <TextField source="shape" />
      <TextField source="cup" />
      <NumberField label="Surface [mm²]" source="surface_in_mm2" />
      <DateField source="created_at" />
      <DateField source="updated_at" />
      <EditButton />
    </Datagrid>
  </List>
);

const validatePunchTip = (values) => {
  const errors = {};
  //Hotfix https://redmine.teuffel.io/issues/1013
  // In the following validation no array is given. Instead only a string...
  if(!values.shape){
    errors.shape = 'Shape is required'
  }
  if (values.shape && values.shape == 'Round') {
    if(!values.diameter_in_mm){
      errors.diameter_in_mm = 'Shape is round! Diameter is required';
    }
  }
  if (values.shape && values.shape != 'Round') {
    if(!values.length_in_mm){
      errors.length_in_mm = 'Shape is not round! Length is required';
    }
    if(!values.width_in_mm){
      errors.width_in_mm = 'Shape is not round! Width  is required';
    }
  }
  if (values.shape && (values.shape == 'Special' || values.shape == 'Football shaped')) {
    if(!values.special_name){
      errors.special_name = 'Shape is ' + values.shape + '! Special name is required';
    }
  }

  if(values.shape && values.shape == 'Football shaped') {
    if(!values.surface_in_mm2){
      errors.surface_in_mm2 = 'Shape is football shaped! Surface is required';
    }
  }

  if(!values.cup){
    errors.cup = 'Cup is required'
  }

  if(values.cup && values.cup != 'no' ){
    if(!values.radius_of_curvature_up_in_mm){
      if(values.shape != 'Football shaped' && values.shape != 'Special'){
        errors.radius_of_curvature_up_in_mm = 'Cup is yes! Radius of curvature UP is required'
      }
    }
    if(!values.cup_volume_up_in_mm3){
      errors.cup_volume_up_in_mm3 = 'Cup is yes! Cup volume for UP is required'
    }
    if(!values.cup_depth_up_in_mm){
      errors.cup_depth_up_in_mm = 'Cup is yes! Cup depth for UP is required'
    }
  }
  return errors
};

export const PunchTipEdit = props => (
  <Edit title={<PunchTipTitle/>} {...props}>
    <SimpleForm redirect="show" validate={validatePunchTip}>
      <DependentSetter />
      <Grid container spacing={2}>
        <Grid container>
          <Grid item xs={2} >
            <SelectInput source="shape" choices={[
              { id: 'Round', name: 'Round'},
              { id: 'Capsule shaped', name: 'Capsule shaped'},
              { id: 'Football shaped', name: 'Football shaped'},
              { id: 'Special', name: 'Special'}
            ]}/>
            <SelectInput source="cup" choices={[
              { id: 'no', name: 'no (flat)'},
              { id: 'UP-LP equal', name: 'yes (UP-LP equal)'},
              { id: 'UP-LP different', name: 'yes (UP-LP different)'}
            ]}/>
            <TextInput source="special_name" />
          </Grid>
          <Grid item xs={2} >
            <FormDataConsumer>
              {
                ({ formData, ...rest }) => {
                  if(formData && (!formData.shape || formData.shape == 'Round')) {
                    formData.length_in_mm = null
                    formData.width_in_mm = null
                    return <NumberInput source="diameter_in_mm" />
                  }
                }
              }
            </FormDataConsumer>
            <FormDataConsumer>
              {
                ({ formData, ...rest }) => {
                  if(formData && (!formData.shape || formData.shape != 'Round')){
                    formData.diameter_in_mm = null
                    return <Fragment>
                      <NumberInput source="length_in_mm" />
                      <NumberInput source="width_in_mm" />
                    </Fragment>
                  }
                }
              }
            </FormDataConsumer>
            <NumberInput source="surface_in_mm2" />
          </Grid>

          <FormDataConsumer>
            {({formData, ...rest}) => (
              formData && (!formData.cup || formData.cup != 'no') && (!formData.shape || formData.shape == 'Round' || formData.shape == 'Capsule shaped') &&
              <Grid item xs={2} >
                <Fragment>
                  <NumberInput label="Radius of curv. up in mm" source="radius_of_curvature_up_in_mm" />
                  <NumberInput disabled={formData.cup === 'UP-LP equal' ? true : false} label="Radius of curv. lp in mm" source="radius_of_curvature_lp_in_mm" />
                </Fragment>
              </Grid>
            )}
          </FormDataConsumer>
          <FormDataConsumer>
            {({formData, ...rest}) => (
              formData && (!formData.cup || formData.cup != 'no') &&
              <Grid item xs={2} >
                <Fragment>
                  <NumberInput source="cup_volume_up_in_mm3" />
                  <NumberInput disabled={formData.cup === 'UP-LP equal' ? true : false} source="cup_volume_lp_in_mm3" />
                </Fragment>
              </Grid>
            )}
          </FormDataConsumer>
          <FormDataConsumer>
            {({formData, ...rest}) => (
              formData && (!formData.cup || formData.cup != 'no') &&
              <Grid item  xs={2} >
                <Fragment>
                  <NumberInput source="cup_depth_up_in_mm" />
                  <NumberInput disabled={formData.cup === 'UP-LP equal' ? true : false} source="cup_depth_lp_in_mm" />
                </Fragment>
              </Grid>
            )}
          </FormDataConsumer>
        </Grid>
        <Grid item xs={12} >
          <RichTextInput source="description" />
        </Grid>
      </Grid>
    </SimpleForm>
  </Edit>
);


const DependentSetter = ({...props}) => {
  const valuesRef = useRef({ cup: props.record.cup });
  const form = useForm();
  return <FormSpy
    subscription={{ values: true }}
    onChange={({ values }) => {
      if(values.cup === "UP-LP equal") {
        form.change("radius_of_curvature_lp_in_mm", values.radius_of_curvature_up_in_mm)
        form.change("cup_volume_lp_in_mm3", values.cup_volume_up_in_mm3)
        form.change("cup_depth_lp_in_mm", values.cup_depth_up_in_mm)
      }
      valuesRef.current = values
    }}
  />
}

export const PunchTipCreate = props => (
  <Create {...props}>
    <SimpleForm redirect="show" validate={validatePunchTip}>
      <DependentSetter />
      <Grid container spacing={2}>
        <Grid container>
          <Grid item xs={2} >
            <SelectInput source="shape" choices={[
              { id: 'Round', name: 'Round'},
              { id: 'Capsule shaped', name: 'Capsule shaped'},
              { id: 'Football shaped', name: 'Football shaped'},
              { id: 'Special', name: 'Special'}
            ]}/>
            <SelectInput source="cup" choices={[
              { id: 'no', name: 'no (flat)'},
              { id: 'UP-LP equal', name: 'yes (UP-LP equal)'},
              { id: 'UP-LP different', name: 'yes (UP-LP different)'}
            ]}/>
            <TextInput source="special_name" />
          </Grid>
          <Grid item xs={2} >
            <FormDataConsumer>
              {
                ({ formData, ...rest }) => {
                  if(formData && (!formData.shape || formData.shape == 'Round')) {
                    formData.length_in_mm = ""
                    formData.width_in_mm = ""
                    return <NumberInput source="diameter_in_mm" />
                  }
                }
              }
            </FormDataConsumer>
            <FormDataConsumer>
              {
                ({ formData, ...rest }) => {
                  if(formData && (!formData.shape || formData.shape != 'Round')){
                    formData.diameter_in_mm = null
                    return <Fragment>
                      <NumberInput source="length_in_mm" />
                      <NumberInput source="width_in_mm" />
                    </Fragment>
                  }
                }
              }
            </FormDataConsumer>
            <NumberInput source="surface_in_mm2" />
          </Grid>
          <FormDataConsumer>
            {({formData, ...rest}) => (
              formData && (!formData.cup || formData.cup != 'no') && (!formData.shape || formData.shape == 'Round' || formData.shape == 'Capsule shaped') &&
              <Grid item xs={2} >
                <Fragment>
                  <NumberInput label="Radius of curv. up in mm" source="radius_of_curvature_up_in_mm" />
                  <NumberInput disabled={formData.cup === 'UP-LP equal' ? true : false} label="Radius of curv. lp in mm" source="radius_of_curvature_lp_in_mm" />
                </Fragment>
              </Grid>
            )}
          </FormDataConsumer>
          <FormDataConsumer>
            {({formData, ...rest}) => (
              formData && (!formData.cup || formData.cup != 'no') &&
              <Grid item xs={2} >
                <Fragment>
                  <NumberInput source="cup_volume_up_in_mm3" />
                  <NumberInput disabled={formData.cup === 'UP-LP equal' ? true : false} source="cup_volume_lp_in_mm3" />
                </Fragment>
              </Grid>
            )}
          </FormDataConsumer>
          <FormDataConsumer>
            {({formData, ...rest}) => (
              formData && (!formData.cup || formData.cup != 'no') &&
              <Grid item  xs={2} >
                <Fragment>
                  <NumberInput source="cup_depth_up_in_mm" />
                  <NumberInput disabled={formData.cup === 'UP-LP equal' ? true : false} source="cup_depth_lp_in_mm" />
                </Fragment>
              </Grid>
            )}
          </FormDataConsumer>
        </Grid>
        <Grid item xs={12} >
          <RichTextInput source="description" />
        </Grid>
      </Grid>
    </SimpleForm>
  </Create>
);

const PunchTipTitle = ({record}) => {
  return <span>PunchTip - { record ? `${record.name}` : ''}</span>
}

export const PunchTipShow = props => (
  <Show title={<PunchTipTitle/>} {...props}>
    <TabbedShowLayout>
      <Tab label="Details">
        <TextField source="name" />
        <TextField source="shape" />
        <TextField source="cup" />
        <NumberField source="radius_of_curvature_up_in_mm" />
        <NumberField source="radius_of_curvature_lp_in_mm" />
        <NumberField source="diameter_in_mm" />
        <NumberField source="length_in_mm" />
        <NumberField source="width_in_mm" />
        <NumberField source="surface_in_mm2" />
        <NumberField source="cup_volume_up_in_mm3" />
        <NumberField source="cup_volume_lp_in_mm3" />
        <NumberField source="cup_depth_up_in_mm" />
        <NumberField source="cup_depth_lp_in_mm" />
        <TextField source="special_name" />
        <RichTextField source="description" />
        <DateField source="created_at" />
        <DateField source="updated_at" />
      </Tab>
      <Tab label="Trials">
        <ReferenceManyField
          addLabel={false}
          reference="trials"
          source="id"
          target="punch_tip_id"
          sort={{ field: 'updated_at', order: 'DESC' }}
        >
          <Datagrid>
            <ReferenceField label="Name" source="id" reference="trials" link="show"><TextField source="name" /></ReferenceField>
            <DateField source="created_at" />
            <DateField source="updated_at" />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
    </TabbedShowLayout>
  </Show>
);
