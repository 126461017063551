import grey from '@material-ui/core/colors/grey';
import { createMuiTheme } from '@material-ui/core/styles';

const Theme = createMuiTheme({
  typography: {
    fontSize: 13,
  },
  palette: {
    type: 'light', // Switching the dark mode on is a single property value change.
    primary: {
      main: '#e94e1b',
    },
    secondary: grey,
    error: grey,
  },
  overrides: {
    MuiGrid: {
      container: {
        width: '100% !important',
      },
    },
  },
});

export default Theme
