import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Drawer from '@material-ui/core/Drawer';
import { withStyles } from '@material-ui/core/styles';

import IconImageEye from '@material-ui/icons/RemoveRedEye';
import IconKeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { Loading, Button, BooleanField, RichTextField, SimpleShowLayout, TextField, ReferenceManyField, Datagrid, ReferenceField, NumberField } from 'react-admin';
import SDSField from '../substances/sdsField'
import { useGetOne } from 'react-admin';
import Chip from '@material-ui/core/Chip';

const styles = theme => ({
  field: {
    // These styles will ensure our drawer don't fully cover our
    // application when teaser or title are very long
    '& span': {
      display: 'inline-block',
      maxWidth: '20em'
    }
  }
});
const BatchPreviewView = ({ id, classes, ...props }) => {
  const {data, loading, error} = useGetOne('batches', id)
  if (loading) { return <Loading />; }
  return(
    <Fragment>
      <SimpleShowLayout {...props} record={data} >
        <ReferenceField source="formulation_id" reference="formulations" link="show"><TextField source="name" /></ReferenceField>
        <TextField source="special_feature" />
        <BooleanField source="is_a_preblend" />
        <TextField source="batch_size_in_g" />
        <RichTextField source="guidelines" />
        <ReferenceManyField
          addLabel={false}
          reference="batch_items"
          source="id"
          target="batch_id"
          sort={{ field: 'sort_field', order: 'ASC' }}
        >
          <Datagrid>
            <ReferenceField source="ingredient_id" reference="ingredients" link="show"><TextField source="name" /></ReferenceField>
            <NumberField label="Quantity of [g]" source="quantity_of_in_g" />
          </Datagrid>
        </ReferenceManyField>
      </SimpleShowLayout>
    </Fragment>
  );
};

const SubstanceTypesField = ({ record }) => {
  if(record && record.substance_types){
    return record.substance_types.map(item => (
      <Chip key={item.id} label={item.name} />
    ))
  }else{
    return ""
  }
}
SubstanceTypesField.defaultProps = {
    addLabel: true
};

const SubstancePreviewView = ({ id, classes, ...props }) => {
  const {data, loading, error} = useGetOne('substances', id)
  if (loading) { return <Loading />; }
  return(
    <SimpleShowLayout {...props} record={data} >
      <TextField source="brandname" />
      <SDSField label="SDS" source="security_data_sheet.src" title="security_data_sheet.title" />
      <ReferenceField source="producer_id" reference="producers" link={false} ><TextField source="name" /></ReferenceField>
      <TextField source="chemical_name" />
      <SubstanceTypesField label="Substance Types" />
      <NumberField source="powder_particle_density" />
      <TextField source="powder_particle_density_determination" />
    </SimpleShowLayout>
  );
};

const IngredientPreviewView = ({ingredient_id, classes, ...props}) => {
  const {data, loading, error} = useGetOne('ingredients', ingredient_id)
  if (loading) { return <Loading />; }
  if(data.actual_type == "Substances::Substance"){
    return <SubstancePreviewView id={data.actual_id} basePath="/substances" resource="substances" />
  }
  else{
    return <BatchPreviewView id={data.actual_id} basePath="/batches" resourece="batches" />
  }
}

const mapStateToProps = (state, props) => ({
  // Get the record by its id from the react-admin state.
  record: state.admin.resources[props.resource]
  ? state.admin.resources[props.resource].data[props.id]
  : null,
  version: state.admin.ui.viewVersion
});

const IngredientPreview = connect(mapStateToProps, {})(
  withStyles(styles)(IngredientPreviewView)
);

class IngredientQuickPreviewButton extends Component {
  state = { showPanel: false };

  handleClick = () => {
    this.setState({ showPanel: true });
  };

  handleCloseClick = () => {
    this.setState({ showPanel: false });
  };

  render() {
    const { showPanel } = this.state;
    const { ingredient_id } = this.props;
    return (
      <Fragment>
        <Button className="ra-input preview-button" onClick={this.handleClick} label="ra.action.show">
          <IconImageEye />
        </Button>
        <Drawer
          anchor="right"
          open={showPanel}
          onClose={this.handleCloseClick}
        >
          <div>
            <Button label="Close" onClick={this.handleCloseClick}>
              <IconKeyboardArrowRight />
            </Button>
          </div>
          <IngredientPreview ingredient_id={ingredient_id} />
        </Drawer>
      </Fragment>
    );
  }
}

export default connect()(IngredientQuickPreviewButton);
