import React from 'react';
import { List, Datagrid, TextField, BooleanField, DateField, ReferenceField, EditButton, NumberField, Filter} from 'react-admin';
import { Show, TabbedShowLayout, Tab, ShowButton, ArrayField, ReferenceManyField, CloneButton, Labeled } from 'react-admin';
import { Edit, FileInput, FileField, SimpleForm, SelectInput, TextInput, ReferenceInput, DateInput, BooleanInput, NumberInput } from 'react-admin';
import { Create, AutocompleteInput, ArrayInput, ReferenceArrayInput, SelectArrayInput, SimpleFormIterator, ChipField, required } from 'react-admin';
import Chip from '@material-ui/core/Chip';
import SDSField from './sdsField'
import Pagination from '../common/Pagination'

//Tune the export https://marmelab.com/blog/2018/08/16/react-admin-2-2.html#export-button

const SubstanceFilter = props => (
  <Filter {...props}>
    <TextInput source="brandname"/>
    <TextInput source="chemical_name"/>
    <ReferenceInput label="Substance type" resource="substances" source="substance_types.id" reference="substance_types" sort={{ field: 'name', order: 'ASC' }}><SelectInput optionText="name"/></ReferenceInput>
    <ReferenceInput resource="substances" source="producer_id" reference="producers" sort={{ field: 'name', order: 'ASC' }} perPage={1000}><SelectInput optionText="name"/></ReferenceInput>
  </Filter>
);

const SubstanceTypesField = ({ record }) => {
  if(record && record.substance_types){
    return record.substance_types.map(item => (
      <Chip key={item.id} label={item.name} />
    ))
  }else{
    return ""
  }
}
SubstanceTypesField.defaultProps = {
    addLabel: true
};

export const SubstanceList = props => (
  <List {...props} filters={<SubstanceFilter />} sort={{ field: 'brandname', order: 'ASC' }} pagination={<Pagination />} perPage={50}>
    <Datagrid>
      <TextField source="brandname" />
      <TextField source="chemical_name" />
      <SubstanceTypesField label="Substance Types" />
      <ReferenceField source="producer_id" reference="producers" link="show"><TextField source="name" /></ReferenceField>
      <SDSField label="SDS" source="security_data_sheet.src" title="" sortable={false} />
      <NumberField label="PPD" source="powder_particle_density" />
      <TextField label="PPD determination" source="powder_particle_density_determination" />
      <ShowButton />
      <EditButton />
    </Datagrid>
  </List>
);


const SubstanceTitle = ({record}) => {
  return <span>Substance - { record ? `${record.brandname}` : ''}</span>
}

export const SubstanceShow = props => (
  <Show title={<SubstanceTitle />} {...props}>
    <TabbedShowLayout>
      <Tab label="Summary">
        <TextField source="brandname" />
        <ReferenceField source="producer_id" reference="producers" link="show"><TextField source="name" /></ReferenceField>
        <TextField source="chemical_name" />
        <SubstanceTypesField  label="Substance Types"/>
        <NumberField source="powder_particle_density" />
        <TextField source="powder_particle_density_determination" />
        <SDSField label="Security data sheet" source="security_data_sheet.src" title="security_data_sheet.title" />
      </Tab>
      <Tab label="Used in Formulations">
        <ReferenceManyField
          addLabel={false}
          reference="compositions"
          source="ingredient_id"
          target="ingredient_id"
          sort={{ field: 'updated_at', order: 'DESC' }}
        >
          <Datagrid>
            <ReferenceField source="formulation_id" reference="formulations" link="show"><TextField source="name" /></ReferenceField>
            <DateField source="created_at" />
            <DateField source="updated_at" />
          </Datagrid>
        </ReferenceManyField>

      </Tab>
    </TabbedShowLayout>
  </Show>
);

const PPDChoices = [
  { id: 'measured', name: 'Measured' },
  { id: 'calculated', name: 'Calculated' },
  { id: 'estimated', name: 'Estimated' },
];

export const SubstanceEdit = props => (
  <Edit title={<SubstanceTitle />} {...props}>
    <SimpleForm redirect="show">
      <TextInput label="Brandname" source="brandname" validate={required()}/>
      <ReferenceInput validate={required()} label="Producer" source="producer_id" resource="producers" reference="producers" perPage={1000} sort={{field: 'name', order: 'ASC'}}><SelectInput source="name" /></ReferenceInput>
      <TextInput label="Chemical name" source="chemical_name" validate={required()} />
      <ReferenceArrayInput label="Type" resource="substances" source="substance_type_ids" reference="substance_types" sort={{ field: 'name', order: 'ASC' }}><SelectArrayInput optionText="name"/></ReferenceArrayInput>
      <NumberInput label="PPD" source="powder_particle_density" />
      <SelectInput label="PPD determination?" source="powder_particle_density_determination" choices={PPDChoices} allowEmpty/>
      <FileInput label="Security data sheet" source="security_data_sheet" multiple={false}>
        <SDSField source="src" title="title" />
      </FileInput>
    </SimpleForm>
  </Edit>
);

export const SubstanceCreate = props => (
  <Create title={<SubstanceTitle />} {...props}>
    <SimpleForm redirect="show">
      <TextInput label="Brandname" source="brandname" validate={required()}/>
      <ReferenceInput validate={required()} label="Producer" source="producer_id" resource="producers" reference="producers" perPage={1000} sort={{field: 'name', order: 'ASC'}}><SelectInput source="name" /></ReferenceInput>
      <TextInput label="Chemical name" source="chemical_name" validate={required()} />
      <ReferenceArrayInput label="Type" resource="substances" source="substance_type_ids" reference="substance_types" perPage={1000} sort={{ field: 'name', order: 'ASC' }} ><SelectArrayInput optionText="name" validate={required()}/></ReferenceArrayInput>
      <NumberInput label="PPD" source="powder_particle_density" />
      <SelectInput label="PPD determination?" source="powder_particle_density_determination" choices={PPDChoices} allowEmpty/>
      <FileInput label="Security data sheet" source="security_data_sheet" multiple={false}>
        <SDSField source="src" title="title" />
      </FileInput>
    </SimpleForm>
  </Create>
);
