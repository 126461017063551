import React, { Fragment, useState } from 'react';
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import QuantityCompositionInput from './QuantityCompositionInput'
import PreblendCompositionInput from './PreblendCompositionInput'
import { NumberInput, BooleanInput } from 'react-admin';
import {useForm} from 'react-final-form';


const CompositionInput = (props) => {
  const form = useForm();
  const [state, setState] = useState({
    preblendComposition: (props.record.formulation_type == "Formulating::PreblendComposition") ? true : false,
  });

  const handleChange = name => event => {
    setState({ ...state, [name]: event.target.checked });
  };

  let currentCompositionInput = <QuantityCompositionInput />
  if(state.preblendComposition){
    currentCompositionInput = <PreblendCompositionInput />
  }

  form.change("this_is_a_preblend_composition", state.preblendComposition)
  
  return <Fragment>
    <BooleanInput source="this_is_a_preblend_composition" style={{display: 'none'}}/> 
    <FormGroup row>
      <FormControlLabel
        control={
          <Switch
            onChange={handleChange('preblendComposition')}
            checked={ state.preblendComposition }
            value="preblendComposition"
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />
        }
        label="Percentage Composition"
      />
    </FormGroup>
    { currentCompositionInput }
  </Fragment>
}
export default CompositionInput
