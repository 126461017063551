import React from 'react';
import { List, Datagrid, TextField, DateField, EditButton } from 'react-admin';
import { Edit, SimpleForm, SelectInput, TextInput, ReferenceInput, DateInput } from 'react-admin';
import { Create } from 'react-admin';
import { Show, TabbedShowLayout, ReferenceManyField, ReferenceField, Tab, ShowButton, required } from 'react-admin';
import Pagination from '../common/Pagination'

export const MachineList = props => (
    <List {...props} pagination={<Pagination />} perPage={50}>
        <Datagrid rowClick="show">
            <TextField source="name" />
            <DateField source="created_at" />
            <DateField source="updated_at" />
        </Datagrid>
    </List>
);

export const MachineEdit = props => (
  <Edit title={<MachineTitle/>}{...props}>
    <SimpleForm redirect="show">
      <TextInput source="name" validate={required("Name is required")}/>
    </SimpleForm>
  </Edit>
);

export const MachineCreate = props => (
  <Create {...props}>
    <SimpleForm redirect="show">
      <TextInput source="name" validate={required("Name is required")}/>
    </SimpleForm>
  </Create>
);

const MachineTitle = ({record}) => {
  return <span>Machine - { record ? `${record.name}` : ''}</span>
}

export const MachineShow = props => (
  <Show title={<MachineTitle/>} {...props}>
    <TabbedShowLayout>
      <Tab label="Details">
        <TextField source="name" />
        <DateField source="created_at" />
        <DateField source="updated_at" />
      </Tab>
      <Tab label="Trials">
        <ReferenceManyField
          addLabel={false}
          reference="trials"
          source="id"
          target="machine_id"
          sort={{ field: 'updated_at', order: 'DESC' }}
        >
          <Datagrid>
            <ReferenceField label="Name" source="id" reference="trials" link="show"><TextField source="name" /></ReferenceField>
            <DateField source="created_at" />
            <DateField source="updated_at" />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
    </TabbedShowLayout>
  </Show>
);
