import React from 'react';
import Chart from './chart'

const compressibilityChart = ({...props}) => {
  const showSolidFraction = (props.showSolidFraction == undefined) ? false : props.showSolidFraction

  var yValueVariable = (d) => ((showSolidFraction) ? d.solid_fraction : d.tablet_density_in_g_cm3) 
  var yAccessorVariable = (showSolidFraction) ? "solid_fraction" : "tablet_density_in_g_cm3"
  var yAxisName = (showSolidFraction) ? "Solid Fraction [-]" : "Tablet Density [g/cm³]"

  return <Chart
    title = "Compressibility"
    backendUrl = { `analyses/average-values-for-compressibility-chart/with/${yAccessorVariable}` }
    yAccessor = { yAccessorVariable }
    xAccessor = "average_compaction_pressure_in_mpa"
    yValue = { yValueVariable } 
    xValue = { (d) => (d.average_compaction_pressure_in_mpa) }
    yAxis = { yAxisName }
    xAxis = "Avg. Compaction Pressure [MPa]"
    ticks = "6"
    yExtentAddition = {(min, max) => ([(min/1.3), max + 0.2])}
    xExtentAddition = {(min, max) => ([0, Math.ceil(max + 50)])}
    {...props}
  />
};

export default compressibilityChart;
