import React from 'react';
import { List, Datagrid, TextField, DateField, ReferenceField, EditButton } from 'react-admin';
import { Edit, SimpleForm, SelectInput, TextInput, ReferenceInput, DateInput } from 'react-admin';
import { Create } from 'react-admin';
import { Show, TabbedShowLayout, ReferenceManyField, Tab, ShowButton, required } from 'react-admin';
import Pagination from '../common/Pagination'

export const ProducerList = props => (
  <List {...props} pagination={<Pagination />} perPage={50}>
    <Datagrid rowClick="show">
      <TextField source="name" />
      <DateField source="created_at" />
      <DateField source="updated_at" />
      <EditButton />
    </Datagrid>
  </List>
);

export const ProducerEdit = props => (
  <Edit title={<ProducerTitle/>}{...props}>
    <SimpleForm>
      <TextInput source="name" validate={required("Name is required")}/>
    </SimpleForm>
  </Edit>
);

export const ProducerCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" validate={required("Name is required")}/>
    </SimpleForm>
  </Create>
);

const ProducerTitle = ({record}) => {
  return <span>Producer - { record ? `${record.name}` : ''}</span>
}

export const ProducerShow = props => (
  <Show title={<ProducerTitle/>} {...props}>
    <TabbedShowLayout>
      <Tab label="Details">
        <TextField source="name" />
        <DateField source="created_at" />
        <DateField source="updated_at" />
      </Tab>
      <Tab label="Substances">
        <ReferenceManyField
          addLabel={false}
          reference="substances"
          source="id"
          target="producer_id"
          sort={{ field: 'updated_at', order: 'DESC' }}
        >
          <Datagrid>
            <ReferenceField label="Name" source="id" reference="substances" link="show"><TextField source="brandname" /></ReferenceField>
            <DateField source="created_at" />
            <DateField source="updated_at" />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
    </TabbedShowLayout>
  </Show>
);
