import React, { Fragment, useRef, useState } from 'react';
import { List, Datagrid, TextField, DateField, ReferenceField, BooleanField, EditButton, Filter, RichTextField } from 'react-admin';
import { Edit, SimpleForm, SelectInput, TextInput, ReferenceInput, SearchInput, DateInput, BooleanInput, NumberInput } from 'react-admin';
import { Create, AutocompleteInput, ArrayInput, SimpleFormIterator, FormDataConsumer } from 'react-admin';
import { ShowController, ShowView, Show, TabbedShowLayout, Tab, ShowButton, DeleteButton, ArrayField, ReferenceManyField, CloneButton, FunctionField, Link, NumberField, required} from 'react-admin';

import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import { withStyles } from '@material-ui/core/styles';
import { Button } from 'react-admin';

import { Alert, AlertTitle } from '@material-ui/lab';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import DownloadTrialLogButton from './downloadTrialLogButton'
import DownloadMeasurementTemplate from './downloadMeasurementTemplate'
import DownloadMeasurementButton from './downloadMeasurementButton'
import ApproveButton from './approveButton'
import EditTabletButton from './EditTabletButton'

import UploadFilesButton from './uploadFilesButton'
import UploadMeasurementCSVButton from './uploadMeasurementCSVButton'
import DownloadFileButton from './downloadFileButton'

import PDF from '@material-ui/icons/PictureAsPdf';
import File from '@material-ui/icons/PhotoLibrary';

import { parse } from "query-string";
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';

import { FormSpy, useForm } from 'react-final-form'

import BondabilityChart from '../charts/bondabilityChart'
import CompressibilityChart from '../charts/compressibilityChart'
import CompactabilityChart from '../charts/compactabilityChart'
import ForceCorrelationChart from '../charts/forceCorrelationChart'
import ForceWeightCorrelationChart from '../charts/forceWeightCorrelationChart'
import RelevantLevelCharts from '../charts/relevantLevelCharts'
import BulkAnalyzeButton from './BulkAnalyzeButton'

import IngredientReferenceField from '../ingredients/IngredientReferenceField';
import BatchReferenceInput from './BatchReferenceInput'
import DisableTabletButton from './DisableTabletButton'
import DisabledTabletsField from './DisabledTabletsField'
import RemoveFileButton from './RemoveFileButton'
import Pagination from '../common/Pagination'
import './trial.css'

const PurposeChoices = [
  { id: 'FH-P', name: 'FH-P' },
  { id: 'Ribletts', name: 'Ribletts' },
  { id: 'Samples', name: 'Samples' }
];

// Autocomplete im Filter macht wohl keinen Sinn, oder? Man sucht irgendwie doppelt
//<ReferenceInput source="formulation_id" reference="formulations" filterToQuery={searchText => ({ name: searchText })}  sort={{ field: 'name', order: 'ASC' }}><AutocompleteInput source="name" /></ReferenceInput>
const TrialFilter = props => (
  <Filter {...props}>
    <SearchInput source="q" alwaysOn />
    <ReferenceInput alwaysOn resettable source="customer_id" reference="customers" filterToQuery={searchText => ({ name: searchText })}  sort={{ field: 'name', order: 'ASC' }}><AutocompleteInput optionText="name" /></ReferenceInput>
    <FormDataConsumer alwaysOn form={"filterForm"} source="project_id" >
      {({ formData, ...rest }) =>
        <ReferenceInput resettable source="project_id" reference="projects" filter={formData && { customer_id: formData.customer_id }} filterToQuery={searchText => ({ name: searchText })}  sort={{ field: 'name', order: 'ASC' }} allowEmpty helperText={false}><AutocompleteInput optionText="name" /></ReferenceInput>
      }
    </FormDataConsumer>
    <FormDataConsumer form={"filterForm"} label="Tooling" source="punch_tip_id" >
      {({ formData, ...rest }) =>
        <ReferenceInput label="Tooling" source="punch_tip_id" reference="punch_tips" filterToQuery={searchText => ({ name: searchText })}  sort={{ field: 'name', order: 'ASC' } } allowEmpty helperText={false}><AutocompleteInput optionText="name" /></ReferenceInput>
      }
    </FormDataConsumer>
    <FormDataConsumer alwaysOn form={"filterForm"} source="trial_serie_id" >
      {({ formData, ...rest }) =>
        <ReferenceInput resettable source="trial_serie_id" reference="trial_series" filter={formData && { project_id: formData.project_id }} filterToQuery={searchText => ({ name: searchText })}  sort={{ field: 'name', order: 'ASC' } } allowEmpty helperText={false}><AutocompleteInput optionText="name" /></ReferenceInput>
      }
    </FormDataConsumer>
    <SelectInput source="purpose" choices={PurposeChoices}/>
    <BooleanInput source="approved" />
    <ReferenceInput source="formulation_id" reference="formulations" filterToQuery={searchText => ({ name: searchText })}  sort={{ field: 'name', order: 'ASC' }}><AutocompleteInput optionText="name" /></ReferenceInput>
  </Filter>
);

//<TextField source="machine" />
//<BooleanField source="approved" />
//<TextField source="execution_on" />

export const TrialShortList = props => (
  <List {...props}>
    <Datagrid rowClick="show">
      <ReferenceField source="formulation_id" reference="formulations" link={"show"}><TextField source="name" /></ReferenceField>
      <TextField source="purpose" />
      <TextField source="short_name" />
      <ReferenceField source="punch_head_id" reference="punch_heads"><TextField source="name" /></ReferenceField>
      <ReferenceField source="punch_tip_id" reference="punch_tips"><TextField source="name" /></ReferenceField>
      <BooleanField source="approved" />
      <DateField source="created_at" />
      <DateField source="updated_at" />
      <EditButton />
    </Datagrid>
  </List>
);

const TrialsBulkActionButtons = props => (
  <Fragment>
    <BulkAnalyzeButton {...props} />
  </Fragment>
);

export const TrialList = props => (
  <List {...props} filters={<TrialFilter/>} bulkActionButtons={<TrialsBulkActionButtons />} sort={{field: 'created_at', order: 'DESC' }} pagination={<Pagination />} perPage={50}>
    <Datagrid rowClick="show">
      <TextField source="name" />
      <TextField source="short_name" />
      <TextField label="Tooling" source="punch_tip_name" />
      <ReferenceField source="trial_serie_id" reference="trial_series" link={false}><TextField source="name" /></ReferenceField>
      <ReferenceField source="project_id" reference="projects" link={false}><TextField source="name" /></ReferenceField>
      <ReferenceField source="customer_id" reference="customers" link={false}><TextField source="name" /></ReferenceField>
      <TextField source="purpose" />
      <BooleanField label="Approved?" source="approved" />
      <BooleanField label="Measurements?" source="any_tablets_measured" />
      <DateField source="created_at" />
    </Datagrid>
  </List>
);


const DependentResetter = ({...props}) => {
  const valuesRef = useRef({machine_id: props.record.machine_id});
  const form = useForm();
  return <FormSpy
    subscription={{ values: true }}
    onChange={({ values }) => {
      const next = { ...values };
      if (values.machine_id !== valuesRef.current.machine_id) {
        form.change("profile_id", null)
      }
      valuesRef.current = values;
    }}
  />
}

export const TrialEdit = props => {

  return <Edit title={<TrialTitle />} {...props}>
    <SimpleForm redirect="show" validate={validateTrial}>
      <DependentResetter />
      <Grid container spacing={2}>
        <Grid item xs={3} >
          <ReferenceInput label="Customer Filter" source="customer_id" reference="customers" filterToQuery={searchText => ({ name: searchText })}  sort={{ field: 'name', order: 'ASC' }}><AutocompleteInput optionText="name" /></ReferenceInput>
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              <ReferenceInput label="Project Filter" source="project_id" reference="projects" filter={formData && { customer_id: formData.customer_id }} filterToQuery={searchText => ({ name: searchText })}  sort={{ field: 'name', order: 'ASC' }}><AutocompleteInput optionText="name" /></ReferenceInput>
            }
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              <ReferenceInput label="TrialSerie" source="trial_serie_id" reference="trial_series" filter={formData && { project_id: formData.project_id }} filterToQuery={searchText => ({ name: searchText })}  sort={{ field: 'name', order: 'ASC' }}><AutocompleteInput optionText="name" validate={required()} /></ReferenceInput>

            }
          </FormDataConsumer>
        </Grid>
        <Grid item xs={3} >
          <BatchReferenceInput source="batch_id" reference="batches" filterToQuery={searchText => ({ name: searchText })}  sort={{ field: 'identification', order: 'DESC' }} validate={required()}/>
          <TextInput source="short_name" /><br/>
          <SelectInput source="purpose" choices={PurposeChoices} validate={required()}/>
        </Grid>
        <Grid item xs={3} >
          <ReferenceInput label="Machine" source="machine_id" reference="machines" filterToQuery={searchText => ({ name: searchText })}  sort={{ field: 'name', order: 'ASC' }} validate={required()}><AutocompleteInput optionText="name" /></ReferenceInput>
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              <ReferenceInput
                label="Profile"
                source="profile_id"
                reference="profiles"
                filter={formData && { "machines.id": formData.machine_id }}
                filterToQuery={searchText => ({ name: searchText })}
                sort={{ field: 'name', order: 'ASC' }}
                validate={required()}
              >
                <AutocompleteInput optionText="name" />
              </ReferenceInput>
            }
          </FormDataConsumer>
          <NumberInput source="machine_speed" label="Machine speed" validate={required()}/>
        </Grid>
        <Grid item xs={3} >
          <ReferenceInput label="Punch Head" source="punch_head_id" reference="punch_heads" filterToQuery={searchText => ({ name: searchText })}  sort={{ field: 'name', order: 'ASC' }} validate={required()}><AutocompleteInput optionText="name" /></ReferenceInput>
          <ReferenceInput label="Punch Tip" source="punch_tip_id" reference="punch_tips" filterToQuery={searchText => ({ name: searchText })}  sort={{ field: 'name', order: 'ASC' }} validate={required()} ><AutocompleteInput optionText="name" /></ReferenceInput>
        </Grid>
        <Grid item xs={12} >
          <ArrayInput source="levels" validate={required()}>
            <SimpleFormIterator>
              <NumberInput label="Level [MPa]" source="level_in_mpa" validate={required()} formClassName="level"/>
              <NumberInput label="Tablet Count" source="tablet_count" validate={required()} formClassName="tablet_count" />
              <NumberInput label="Additional" source="additional_tablet_count"  formClassName="additional"/>
            </SimpleFormIterator>
          </ArrayInput>
        </Grid>
      </Grid>
    </SimpleForm>
  </Edit>
};

const validateTrial = (values) => {
  const errors = {};
  if(!values.trial_serie_id) {
    errors.trial_serie_id = 'Required'
  }
  return errors;
}

export const TrialCreate = props => {

  //https://stackoverflow.com/questions/53278585/custom-layout-in-simpleform-component-on-react-admin
  //Die Warnings: Warning: Failed prop type: The prop `resource` is marked as required in `ReferenceInput`, but its value is `undefined`.
  // kommen auch daher
  return(
    <Create {...props} >
      <SimpleForm redirect="show" validate={validateTrial} >
        <DependentResetter />
        <Grid container spacing={2}>
          <Grid item xs={3} >
            <ReferenceInput label="Customer Filter" source="customer_id" reference="customers" filterToQuery={searchText => ({ name: searchText })}  sort={{ field: 'name', order: 'ASC' }}><AutocompleteInput optionText="name" /></ReferenceInput>
            <FormDataConsumer>
              {({ formData, ...rest }) =>
                <ReferenceInput label="Project Filter" source="project_id" reference="projects" filter={formData && { customer_id: formData.customer_id }} filterToQuery={searchText => ({ name: searchText })}  sort={{ field: 'name', order: 'ASC' }}><AutocompleteInput optionText="name" /></ReferenceInput>
              }
            </FormDataConsumer>
            <FormDataConsumer>
              {({ formData, ...rest }) =>
                <ReferenceInput label="TrialSerie" source="trial_serie_id" reference="trial_series" filter={ formData && { project_id: formData.project_id }} filterToQuery={searchText => ({ name: searchText })}  sort={{ field: 'name', order: 'ASC' }}><AutocompleteInput optionText="name" /></ReferenceInput>

              }
            </FormDataConsumer>
          </Grid>

          <Grid item xs={3} >
            <BatchReferenceInput source="batch_id" reference="batches" filterToQuery={searchText => ({ name: searchText })}  sort={{ field: 'identification', order: 'DESC' }} validate={required()}/>
            <TextInput source="short_name" /><br />
            <SelectInput source="purpose" choices={PurposeChoices} validate={required()} />
          </Grid>
          <Grid item xs={3} >
            <ReferenceInput label="Machine" source="machine_id" reference="machines" filterToQuery={searchText => ({ name: searchText })}  sort={{ field: 'name', order: 'ASC' }} validate={required()} ><AutocompleteInput optionText="name" /></ReferenceInput>
            <FormDataConsumer>
              {({ formData, ...rest }) =>
                <ReferenceInput
                  label="Profile"
                  source="profile_id"
                  reference="profiles"
                  filter={formData && { "machines.id": formData.machine_id }}
                  filterToQuery={searchText => ({ name: searchText })}
                  sort={{ field: 'name', order: 'ASC' }}
                  validate={required()}
                >
                  <AutocompleteInput optionText="name" />
                </ReferenceInput>
              }
            </FormDataConsumer>
            <NumberInput source="machine_speed" label="Machine speed" validate={required()} />
          </Grid>
          <Grid item xs={3} >
            <ReferenceInput label="Punch Head" source="punch_head_id" reference="punch_heads" filterToQuery={searchText => ({ name: searchText })}  sort={{ field: 'name', order: 'ASC' }} validate={required()}><AutocompleteInput optionText="name" /></ReferenceInput>
            <ReferenceInput label="Punch Tip" source="punch_tip_id" reference="punch_tips" filterToQuery={searchText => ({ name: searchText })}  sort={{ field: 'name', order: 'ASC' }} validate={required()}><AutocompleteInput optionText="name" /></ReferenceInput>
          </Grid>
          <Grid item xs={12} >
            <ArrayInput source="levels" initialValue={[
              {level_in_mpa: 50, tablet_count: 5, additional_tablet_count: 0},
              {level_in_mpa: 100, tablet_count: 5, additional_tablet_count: 0},
              {level_in_mpa: 200, tablet_count: 5, additional_tablet_count: 0},
              {level_in_mpa: 300, tablet_count: 5, additional_tablet_count: 0},
            ]} validate={required()}>
            <SimpleFormIterator>
              <NumberInput label="Level [MPa]" source="level_in_mpa" validate={required()} formClassName="level"/>
              <NumberInput label="Tablet Count" source="tablet_count" validate={required()} formClassName="tablet_count" />
              <NumberInput label="Additional" source="additional_tablet_count"  formClassName="additional"/>
            </SimpleFormIterator>
          </ArrayInput>
        </Grid>
      </Grid>
    </SimpleForm>
  </Create>
  );
};

const logCardActionStyle = {
  zIndex: 2,
  float: 'right',
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'flex-end',
  flexWrap: 'wrap',
  padding: 0,
};

const DownloadTrialLogActions = ({ basePath, record, resource }) => (
  <CardActions style={logCardActionStyle}>
    <DownloadTrialLogButton record={record}/>
  </CardActions>
);

const cardActionStyle = {
  zIndex: 2,
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'flex-end',
  flexWrap: 'wrap',
  padding: 0,
};

const TrialShowActions = ({basePath, data, resource}) => {
  return(
    <CardActions style={cardActionStyle}>
      {
        data && !data.approved &&
          <EditButton basePath={basePath} record={data} />
      }
      <CloneButton basePath={basePath} record={data} resource={resource} />
      <ApproveButton record={data}/>
    </CardActions>
  );
};

const TrialTitle = ({record}) => {
  return <span>Trial - { record ? `${record.name}` : ''}</span>
}


const my_style = {
  button: {
    marginTop: '1em'
  }
};

const AddTabletCSVButton = ({ classes, record }) => (
  <Button
    style={my_style.button}
    variant="raised"
    component={Link}
    to={`/tablets/create?trial_id=${record.id}`}
    label="Upload a measurement CSV"
    title="Upload a measurement CSV"
  >
    <ChatBubbleIcon />
  </Button>
);
const measurementRowStyle = (record, index) => {
  var warning = false
  var disabled = false
  var css = {}

  if(record && record.business_rule_violation_as_json !== '[]'){
    warning = true
  }
  if(record && record.is_disabled){
    disabled = true
  }

  if(disabled && warning ){
    css = {
      backgroundColor: 'rgba(117, 64, 64, 0.05)',
      textDecoration: 'line-through',
      color: 'gray'
    }
  }
  if(disabled && !warning ){
    css = {
      backgroundColor: 'rgba(0, 0, 0, 0.05)',
      textDecoration: 'line-through',
      color: 'gray'
    }
  }
  if(!disabled && warning){
    css = {
      backgroundColor: 'rgba(233, 78, 27, 0.05)',
    }
  }


  return css
};

const measurementErrorPanel = ({ id, record, resource }) => {
  if(record.business_rule_violation_as_json === undefined){
    return ""
  }
  if(record.business_rule_violation_as_json !== '[]'){
    const error_list = JSON.parse(record.business_rule_violation_as_json)
    if(error_list){
      const error_list_html = <ul>
        {error_list.map(error => <li key={error.code}>{error.message}</li>)}
      </ul>
      return error_list_html
    }
  }
  return <div>No business rule exception found. Everything is fine...</div>
};


const MeasurementActionButtons = controllerProps => (
  <div style={{ whiteSpace: 'nowrap' }}>
    { controllerProps.record && !controllerProps.record.approved && <DisableTabletButton {...controllerProps}/> }
    { controllerProps.record && !controllerProps.record.approved && <EditTabletButton {...controllerProps}/> }
  </div>
);
//TabbedLayout Trial, Measurements
export const TrialShow = (props) => {
  const [newKey, setNewKey] = useState(Math.random());
  const [showAllValues, setShowAllValues] = useState(true);
  const [showTensileStrength, setShowTensileStrength] = useState(true);
  const [showSolidFraction, setShowSolidFraction] = useState(props.record && props.record.show_solid_fraction );

  const toggleShow = () => {
    setShowAllValues(!showAllValues);
    setNewKey(Math.random());
  };

  const toggleShowTensileStrength = () => {
    setShowTensileStrength(!showTensileStrength);
    setNewKey(Math.random());
  };

  const toggleShowSolidFraction = () => {
    setShowSolidFraction(!showSolidFraction);
    setNewKey(Math.random());
  };

  return(
    <ShowController {...props}>
      { controllerProps =>
        <ShowView title={<TrialTitle />} actions={<TrialShowActions />} {...props} {...controllerProps}>
          <TabbedShowLayout style={{ paddingBottom: '16px'}}>
            <Tab label="Summary">
              { controllerProps.record && controllerProps.record.approval_comment && <Alert severity="success">Approved</Alert> }
              <TextField source="name" />
              <ReferenceField source="trial_serie_id" reference="trial_series" link="show"><TextField source="name" /></ReferenceField>
              <ReferenceField source="customer_id" reference="customers" link="show"><TextField source="name" /></ReferenceField>
              <ReferenceField source="project_id" reference="projects" link="show"><TextField source="name" /></ReferenceField>
              <TextField source="short_name" />
              <TextField source="purpose" />
            </Tab>
            <Tab label="Formulation" path="formulation">
              <ReferenceField source="formulation_id" reference="formulations" link="show"><FunctionField render={record => `${record.name}${(record.total_weight) ? " (" + record.total_weight + " mg)" : ""}`} /></ReferenceField>
              <ReferenceField source="batch_id" reference="batches" allowEmpty link="show"><FunctionField render={record => `${record.identification} - ${record.name}`} /></ReferenceField>

              <ReferenceManyField
                addLabel={false}
                reference="compositions"
                source="formulation_id"
                target="formulation_id"
                sort={{ field: 'portion_of', order: 'DESC' }}
              >
                <Datagrid>
                  <IngredientReferenceField label="Ingredient" batch_item={props.record} />
                  <TextField label="Type" source="ingredient_types" />
                  <NumberField label="Portion of [%]" source="portion_of" />
                  <NumberField label="Quantity of [mg]" source="quantity_of" />
                </Datagrid>
              </ReferenceManyField>
            </Tab>
            <Tab label="Machine & Tooling" path="machine-and-tooling">
              <ReferenceField source="punch_head_id" reference="punch_heads" link="show"><TextField source="name" /></ReferenceField>
              <ReferenceField source="punch_tip_id" reference="punch_tips" link="show"><TextField source="name" /></ReferenceField>
              <ReferenceField source="machine_id" reference="machines" link="show"><TextField source="name" /></ReferenceField>
              <ReferenceField source="profile_id" reference="profiles" link="show"><TextField source="name" /></ReferenceField>
              <FunctionField label="Machine speed" render={record => `${record.machine_speed} ${record.machine_speed_unit}`} />
            </Tab>
            <Tab label="Execution" path="execution">
              <DownloadTrialLogActions />
              <ReferenceManyField
                addLabel={false}
                reference="levels"
                target="trial_id"
                sort={{ field: 'level_in_mpa', order: 'ASC' }}
              >
                <Datagrid>
                  <TextField label="Level [MPa]" source="level_in_mpa" />
                  <TextField source="tablet_count" />
                  <TextField source="additional_tablet_count" />
                </Datagrid>
              </ReferenceManyField>
            </Tab>
            <Tab label="Measurements" path="measurements">
              {
                controllerProps.record && !controllerProps.record.approved &&
                  <UploadMeasurementCSVButton />
              }
              <CardActions style={{float: 'right', display: 'flex', justifyContent: 'flex-end', flexWrap: 'wrap', padding: 0}}>
                {
                  controllerProps.record && !controllerProps.record.approved &&
                    <DownloadMeasurementTemplate record={controllerProps.record}/>
                }
                {
                  controllerProps.record && controllerProps.record.any_tablets_measured &&
                    <DownloadMeasurementButton record={controllerProps.record}/>
                }
              </CardActions>
              <ReferenceManyField
                addLabel={false}
                reference="tablets"
                target="trial_id"
                sort={{ field: 'tablet_number_sort', order: 'ASC' }}
                pagination={<Pagination />}
                perPage={50}
              >
                <Fragment>
                  <Datagrid rowStyle={measurementRowStyle} expand={measurementErrorPanel} >
                    <TextField label="No" source="tablet_number" sortBy="tablet_number_sort" />
                    <TextField label="Target Level [MPa]" source="target_level_in_mpa" />
                    { controllerProps.record && !controllerProps.record.average_forces_to_show && <NumberField label="UP [kN]" source="upper_punch_in_kn" options={{ minimumFractionDigits: 3 }}/> }
                    { controllerProps.record && !controllerProps.record.average_forces_to_show && <NumberField label="LP [kN]" source="lower_punch_in_kn" options={{ minimumFractionDigits: 3 }}/> }
                    { controllerProps.record && controllerProps.record.average_forces_to_show && <NumberField label="Avg. MCF [kN]" source="average_main_compaction_force_in_kn" options={{ minimumFractionDigits: 3 }}/> }
                    { controllerProps.record && controllerProps.record.average_forces_to_show && <NumberField label="Avg. PCF [kN]" source="average_pre_compaction_force_in_kn" options={{ minimumFractionDigits: 3 }}/> }
                    <NumberField label="Avg. Compaction Pressure [MPa]" source="average_compaction_pressure_in_mpa" options={{ minimumFractionDigits: 3 }}/>
                    <NumberField label="Weight [mg]" source="weight_in_mg" options={{ minimumFractionDigits: 3 }}/>
                    <NumberField label="Height [mm]" source="height_in_mm" options={{ minimumFractionDigits: 3 }}/>
                    { controllerProps.record && !controllerProps.record.diameter_to_show && <NumberField label="Length [mm]" source="length_in_mm" options={{ minimumFractionDigits: 3 }}/> }
                    { controllerProps.record && !controllerProps.record.diameter_to_show && <NumberField label="Width [mm]" source="width_in_mm" options={{ minimumFractionDigits: 3 }}/> }
                    { controllerProps.record && controllerProps.record.diameter_to_show && <NumberField label="Diameter [mm]" source="diameter_in_mm" options={{ minimumFractionDigits: 3 }}/> }
                    <NumberField label="Total volume [mm³]" source="total_volume_in_mm3" options={{ minimumFractionDigits: 3 }}/>
                    <NumberField label="Tensile strength [MPa]" source="tensile_strength_in_mpa" options={{ minimumFractionDigits: 3 }}/>
                    <NumberField label="Tablet Density [g/cm³]" source="tablet_density_in_g_cm3" options={{ minimumFractionDigits: 3 }}/>
                    <NumberField label="Solid Fraction [-]" source="solid_fraction" options={{ minimumFractionDigits: 3 }}/>
                    <NumberField label="CF [N]" source="crushing_force_in_n" />
                    <TextField source="capping" />
                    <MeasurementActionButtons />
                  </Datagrid>
                </Fragment>
              </ReferenceManyField>
            </Tab>
            { controllerProps.record && controllerProps.record.any_tablets_measured && <Tab label="Analyses" path="analysis">
            <Grid container spacing={2}>
              <Grid item xs={3}>
              </Grid>
              <Grid item xs={6}>
                { controllerProps.record && controllerProps.record.approval_comment && <Fragment>
                <Alert severity="success">
                  <AlertTitle>Approval Comment</AlertTitle>
                  <RichTextField record={controllerProps.record} source="approval_comment" />
                </Alert>
              </Fragment> }
            </Grid>
            <Grid item xs={3}>
            </Grid>
            <DisabledTabletsField trialId={controllerProps.record.id}/>
            <Grid container justify style={{margin: "20px"}}>
              <Grid container alignItems="center" item xs={4} justify="center">
                <Grid item><Typography variant="button">Average values only</Typography></Grid>
                <Grid item> <Switch checked={showAllValues} onChange={toggleShow} /> </Grid>
                <Grid item><Typography variant="button">All values</Typography></Grid>
              </Grid>
              <Grid container alignItems="center" item xs={4} justify="center">
                <Grid item><Typography variant="button">Crushing force</Typography></Grid>
                <Grid item> <Switch checked={showTensileStrength} onChange={toggleShowTensileStrength} /> </Grid>
                <Grid item><Typography variant="button">Tensile strength</Typography></Grid>
              </Grid>
              <Grid container alignItems="center" item xs={4} justify="center">
                <Grid item><Typography variant="button">Tablet Density</Typography></Grid>
                <Grid item> <Switch disabled checked={showSolidFraction} onChange={toggleShowSolidFraction} /> </Grid>
                <Grid item><Typography variant="button">Solid Fraction</Typography></Grid>
              </Grid>
            </Grid>
            <Grid item xs={4} >
              <CompactabilityChart key={newKey} showAllValues={showAllValues} showTensileStrength={showTensileStrength} trialIds={controllerProps.record && [controllerProps.record.id]}/>
            </Grid>
            <Grid item xs={4} >
              <BondabilityChart key={newKey} showAllValues={showAllValues} showTensileStrength={showTensileStrength} trialIds={controllerProps.record && [controllerProps.record.id]} showSolidFraction={showSolidFraction}/>
            </Grid>
            <Grid item xs={4} >
              <CompressibilityChart key={newKey} showAllValues={showAllValues} trialIds={controllerProps.record && [controllerProps.record.id]}  showSolidFraction={showSolidFraction} />
            </Grid>
          </Grid>
          <Grid container spacing={2} >
            <RelevantLevelCharts trialId={controllerProps.record && [controllerProps.record.id]} />
          </Grid>
          <Grid container spacing={2} style={{paddingBottom: '400px'}}>
            <Grid item xs={4} >
              <ForceCorrelationChart trialIds={controllerProps.record && [controllerProps.record.id]} />
            </Grid>
            <Grid item xs={4} >
              <ForceWeightCorrelationChart trialId={controllerProps.record && [controllerProps.record.id]} />
            </Grid>
          </Grid>
        </Tab> }
        <Tab label="Files" path="files">
          <UploadFilesButton />
          <ReferenceManyField
            addLabel={false}
            reference="trial_files"
            target="trial_id"
            sort={{ field: 'created_at', order: 'DESC' }}
            pagination={<Pagination />}
          >
            <Datagrid>
              <TextField source="filename" sortable={false}/>
              <FunctionField label="Content type" style={{color: "#999"}} render={record => {
                switch(record.content_type) {
                  case 'application/pdf':
                    return <PDF />
                default:
                    return <File />
                }
              }
              }
            />
            <FunctionField label="File size" render={record => `${record.byte_size/1000} kB`} />
            <DateField source="created_at" />
            <DownloadFileButton {...props} />
            <RemoveFileButton {...props} />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
      <Tab label="Audit" path="audit-log">
        <ReferenceManyField
          addLabel={false}
          reference="trial_audit_logs"
          target="trial_id"
          sort={{ field: 'occurred_at', order: 'DESC' }}
          filter={{limit: 100}}
        >
          <Datagrid>
            <DateField source="occurred_at" showTime />
            <TextField source="event" />
            <RichTextField source="user" />
            <RichTextField source="debug" />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
    </TabbedShowLayout>
  </ShowView>
      }
    </ShowController>
  )
};
