import React from 'react';
import { List, Filter, AutocompleteInput, Datagrid, TextField, DateField, ReferenceField } from 'react-admin';
import { Show, TabbedShowLayout, ReferenceManyField, Tab, required } from 'react-admin';
import { Create } from 'react-admin';
import { Edit, SimpleForm, SelectInput, TextInput, ReferenceInput, FormDataConsumer } from 'react-admin';
import Pagination from '../common/Pagination'

const TrialSerieFilter = props => (
  <Filter {...props}>
    <ReferenceInput label="TrialSeries name" source="id" reference="trial_series" filterToQuery={searchText => ({ name: searchText })}  sort={{ field: 'name', order: 'ASC' }}><AutocompleteInput optionText="name" /></ReferenceInput>
  </Filter>
);

export const TrialSeriesList = props => (
  <List filters={<TrialSerieFilter />} {...props} pagination={<Pagination />} perPage={50}>
    <Datagrid rowClick="show">
      <TextField source="name" />
      <ReferenceField source="project_id" reference="projects" link="show"><TextField source="name" /></ReferenceField>
      <DateField source="created_at" />
      <DateField source="updated_at" />
    </Datagrid>
  </List>
);

const TrialSeriesTitle = ({record}) => {
  return <span>TrialSerie - { record ? `${record.name}` : ''}</span>
}

export const TrialSeriesShow = props => (
  <Show title={<TrialSeriesTitle/>} {...props}>
    <TabbedShowLayout>
      <Tab label="Details">
        <TextField source="name" />
        <ReferenceField source="customer_id" reference="customers" link="show">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField source="project_id" reference="projects" link="show">
          <TextField source="name" />
        </ReferenceField>
        <DateField source="created_at" />
        <DateField source="updated_at" />
      </Tab>
      <Tab label="Trials">
        <ReferenceManyField
          addLabel={false}
          reference="trials"
          source="id"
          target="trial_serie_id"
          sort={{ field: 'updated_at', order: 'DESC' }}
        >
          <Datagrid>
            <ReferenceField label="Name" source="id" reference="trials" link="show"><TextField source="name" /></ReferenceField>
            <TextField source="purpose" />
            <DateField source="created_at" />
            <DateField source="updated_at" />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
    </TabbedShowLayout>
  </Show>
);

export const TrialSeriesEdit = props => (
  <Edit title={<TrialSeriesTitle/>}{...props}>
    <SimpleForm redirect="show" >
      <TextInput source="name" validate={required()}/>
      <ReferenceInput label="Customer" source="customer_id" reference="customers" disabled={true}><SelectInput optionText="name" /></ReferenceInput>
      <ReferenceInput label="Project" source="project_id" reference="projects" disabled={true}  sort={{ field: 'name', order: 'ASC' }}><SelectInput optionText="name" /></ReferenceInput>
    </SimpleForm>
  </Edit>
);

export const TrialSeriesCreate = props => (
  <Create {...props}>
    <SimpleForm redirect="show" >
      <TextInput source="name" validate={required()}/>
      <ReferenceInput label="Customer filter" source="customer_id" reference="customers"><SelectInput optionText="name" /></ReferenceInput>
      <FormDataConsumer>
        {({ formData, ...rest }) =>
          <ReferenceInput label="Project" source="project_id" reference="projects" validate={required()} filter={formData && { customer_id: formData.customer_id }} sort={{ field: 'name', order: 'ASC' }}><SelectInput optionText="name" /></ReferenceInput>
        }
      </FormDataConsumer>
    </SimpleForm>
  </Create>
);
