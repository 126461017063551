import React, { Fragment } from 'react';
import IngredientQualityInput from './IngredientQualityInput'
import { NumberInput } from 'react-admin';
import { useForm } from 'react-final-form';

const OneHundredPercent = (value, allValues) => {
  if(value != 0){
    return "Remaining % must equal to 0"
  }

  return [];
}

const CalculateCurrentReaminingPercent = () => {
  const form = useForm();
  const composition = form.getState().values.composition;
  let value = 100;
  if(composition !== undefined){
    value = 100 - composition.reduce((percent_remaining,item) => {
      if(item && item.ingredient){
        var itemValue = parseFloat(item.ingredient.portion_of)
        if(isNaN(itemValue)){
          itemValue = 0
        }
      }
      //console.log("  percent_remaining: " + percent_remaining)
      //console.log("  item Value: " + itemValue)
      return percent_remaining + itemValue
    }, 0)
  }
  return value.toFixed(4);
};

const validateRemainingPercent = [OneHundredPercent]

const PreblendCompositionInput = props => {
  return <Fragment>
    <NumberInput label="Remaining [%]" source="remaining_percent" initialValue={CalculateCurrentReaminingPercent()} disabled validate={validateRemainingPercent}/>
    <IngredientQualityInput />
  </Fragment>
}

export default PreblendCompositionInput;
