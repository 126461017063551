import * as React from "react";
import { AppBar } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    appBar: {
        backgroundColor: '#e94e1b',
    },
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
});

const CustomAppBar = props => {
    const classes = useStyles();
    return (
        <AppBar {...props} className={(process.env.REACT_APP_TEST == 'true') ? classes.appBar : ''}>
            <Typography
                variant="h6"
                color="inherit"
                className={classes.title}
                id="react-admin-title"
            />
        </AppBar>
    );
};

export default CustomAppBar;
