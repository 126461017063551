import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Drawer from '@material-ui/core/Drawer';
import { withStyles } from '@material-ui/core/styles';

import IconImageEye from '@material-ui/icons/RemoveRedEye';
import IconKeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { Loading, Button, SimpleShowLayout, TextField, ReferenceManyField, Datagrid, ReferenceField, NumberField, RichTextField } from 'react-admin';
import IngredientReferenceField from '../ingredients/IngredientReferenceField'
import { useGetOne } from 'react-admin';

const styles = theme => ({
  field: {
    // These styles will ensure our drawer don't fully cover our
    // application when teaser or title are very long
    '& span': {
      display: 'inline-block',
      maxWidth: '20em'
    }
  }
});

const BatchPreviewView = ({ id, classes, ...props }) => {
  const {data, loading, error} = useGetOne('batches', id)
  if (loading) { return <Loading />; }
  return <SimpleShowLayout {...props}>
    <TextField source="formulation_name" />
    <TextField source="identification" />
    <TextField source="special_feature" />
    <RichTextField source="guidelines" />
    <NumberField label="Batch size in [g]" source="batch_size_in_g" />
    <ReferenceManyField
      addLabel={false}
      reference="batch_items"
      source="id"
      target="batch_id"
      sort={{ field: 'sort_field', order: 'ASC' }}
    >
      <Datagrid>
        <IngredientReferenceField label="Ingredient" batch_item={props.record} />
        <TextField source="ingredient_types" />
        <NumberField label="Quantity of [mg]" source="quantity_of_in_g" />
      </Datagrid>
    </ReferenceManyField>
  </SimpleShowLayout>
};

const mapStateToProps = (state, props) => {
  return {
    // Get the record by its id from the react-admin state.
    record: state.admin.resources[props.resource]
    ? state.admin.resources[props.resource].data[props.id]
    : null,
    version: state.admin.ui.viewVersion
  }};

const BatchPreview = connect(mapStateToProps, {})(
  withStyles(styles)(BatchPreviewView)
);

class BatchQuickPreviewButton extends Component {
  state = { showPanel: false };

  handleClick = () => {
    this.setState({ showPanel: true });
  };

  handleCloseClick = () => {
    this.setState({ showPanel: false });
  };

  render() {
    const { showPanel } = this.state;
    const { id } = this.props;
    return (
      <Fragment>
        <Button onClick={this.handleClick} label="ra.action.show">
          <IconImageEye />
        </Button>
        <Drawer
          anchor="right"
          open={showPanel}
          onClose={this.handleCloseClick}
        >
          <div>
            <Button label="Close" onClick={this.handleCloseClick}>
              <IconKeyboardArrowRight />
            </Button>
          </div>
          <BatchPreview id={id} basePath="/batches" resource="batches" {...this.props} />
        </Drawer>
      </Fragment>
    );
  }
}

export default connect()(BatchQuickPreviewButton);
