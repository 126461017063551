// in src/comments/EditTabletButton.js
import React from 'react';
import EditTabletDialog from './EditTabletDialog';


const EditTabletButton = ({ ...props }) => {
  return props.record && !props.record.is_disabled ? <EditTabletDialog onClick={(event) => event.preventDefault} record={props.record} {...props}/> : ''
}

export default EditTabletButton
