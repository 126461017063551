const theme = [
"#f44336",
"#3f51b5",
"#4caf50",
"#ff9800",
"#795548",
"#9e9e9e",
"#ffeb3b",
"#1de9b6",
"#9c27b0",
"#cddc39",
"#ff9100",
"#d500f9"
]
export default theme
