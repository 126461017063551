import React from 'react';
import { ReferenceField, TextField } from 'react-admin';
import { useGetOne } from 'react-admin';

const IngredientReferenceField = (batch_item, {...props}) => {
  const {data, loading, error} = useGetOne('ingredients', batch_item.record.ingredient_id)
  if (loading) { return ""; }

  return <ReferenceField record={batch_item.record} basePath="/ingredients" resource="/ingredients" source="ingredient_id" reference="ingredients" link={(record, reference) => {
    var type = "substances"
    if(data.actual_type == "Formulations::Batch"){
      type = "batches"
    }
    return `/${type}/${data.actual_id}/show`

  }} {...props} ><TextField source="name" /></ReferenceField>
}

export default IngredientReferenceField
