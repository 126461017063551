import React from 'react';
import { Alert, AlertTitle } from '@material-ui/lab';
import Grid from '@material-ui/core/Grid';
import { useGetList, Loading } from 'react-admin';

const DisabledTabletsField = ({ trialId }) => {
  const { data, ids, loading, error } = useGetList('tablets', { page: 1, perPage: 1000 }, {field: 'tablet_number_sort', order: 'ASC'}, {trial_id: trialId, is_disabled: 'true'} );
  if(loading) { return <Loading />}
  if(error) { return "Error"}
  return (ids.length > 0) ? <Grid item xs={12}>
    <Alert severity="info">The following Tablet No. have been disabled and does not affect any chart on this page: {ids.map(id =>
          data[id].tablet_number
      ).join(", ")}
    </Alert>
  </Grid> : null
}
export default DisabledTabletsField;
