import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { Title } from 'react-admin';
import { Link } from '@material-ui/core';
import CurrentApiVersion from './currentApiVersion'
import CurrentViewVersion from './currentViewVersion'
import CurrentAuthVersion from './currentAuthVersion'

import TrialsSummary from '../trials/trials_summary'

const Redmine = ({ticket}) => {
  return <Link href={`https://redmine.teuffel.io/issues/${ticket}`} underline="none" target="_blank" rel="noopener noreferrer">#{ticket}</Link>
}

const ReleaseLogItem = ({text, ticket = null}) => {
  return (ticket) ? <li>{text}, <Redmine ticket={ticket} /></li> : <li>{text}</li>
}

const RedmineVersion = ({version_number, version}) => {
  return <Link href={`https://redmine.teuffel.io/versions/${version_number}`} underline="none" target="_blank" rel="noopener noreferrer">See Redmine Roadmap for {version}</Link>
}

export default () => (
  <Grid container spacing={2}>
    <Title title="SDC - ForceHardness-DB" />
    <Grid item xs={6} >
      <Card>
        <CardContent>
          <Typography color="textSecondary" variant="headline" component="h2">What`s new?</Typography>
          <br />
          <Typography color="textSecondary" variant="caption" component="h4">
            <CurrentApiVersion /> - <CurrentViewVersion /> - <CurrentAuthVersion />
          </Typography>
          <br />
          <Typography color="textSecondary" variant="subheading" component="h4">Version 0.1.28</Typography>
          <ul>
            <Typography color="textSecondary" variant="body1">
              <RedmineVersion version_number="68" version="0.1.28" />
            </Typography>
          </ul>
          <br />
          <Typography color="textSecondary" variant="subheading" component="h4">Version 0.1.27.1</Typography>
          <ul>
            <Typography color="textSecondary" variant="body1">
              <RedmineVersion version_number="70" version="0.1.27.1" />
            </Typography>
          </ul>
          <br />
          <Typography color="textSecondary" variant="subheading" component="h4">Version 0.1.27</Typography>
          <ul>
            <Typography color="textSecondary" variant="body1">
              <RedmineVersion version_number="64" version="0.1.27" />
            </Typography>
          </ul>
          <br />
          <Typography color="textSecondary" variant="subheading" component="h4">Version 0.1.26.1</Typography>
          <ul>
            <Typography color="textSecondary" variant="body1">
              <RedmineVersion version_number="67" version="0.1.26.1" />
            </Typography>
          </ul>
          <br />
          <Typography color="textSecondary" variant="subheading" component="h4">Version 0.1.26</Typography>
          <ul>
            <Typography color="textSecondary" variant="body1">
              <RedmineVersion version_number="58" version="0.1.26" />
            </Typography>
          </ul>
          <br />
          <Typography color="textSecondary" variant="subheading" component="h4">Version 0.1.25.1 (Hotfix)</Typography>
          <ul>
            <Typography color="textSecondary" variant="body1">
              <RedmineVersion version_number="59" version="0.1.25.1" />
            </Typography>
          </ul>
          <br />
          <Typography color="textSecondary" variant="subheading" component="h4">Version 0.1.25</Typography>
          <ul>
            <Typography color="textSecondary" variant="body1">
              <RedmineVersion version_number="57" version="0.1.25" />
            </Typography>
          </ul>
        </CardContent>
      </Card>
    </Grid>
    <Grid item xs={6} >
      <TrialsSummary />
    </Grid>
  </Grid>
);
