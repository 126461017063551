import React from 'react';
import Chart from './chart'

const compactibilityChart = ({...props}) => {
  const showTensileStrength = (props.showTensileStrength == undefined) ? false : props.showTensileStrength

  return <Chart
    title = "Compactability"
    backendUrl = { (showTensileStrength) ? "analyses/average-values-for-compactability-chart/with/tensile_strength_in_mpa" : "analyses/average-values-for-compactability-chart/with/crushing_force_in_n" }
    yAccessor = { (showTensileStrength) ? "tensile_strength_in_mpa" : "crushing_force_in_n" }
    xAccessor = "average_compaction_pressure_in_mpa"
    yAxis = { (showTensileStrength) ? "Tensile Strength [MPa]" : "Crushing force [N]" }
    xAxis = "Avg. Compaction Pressure [MPa]"
    yValue = {(d) => ((showTensileStrength) ? d.tensile_strength_in_mpa : d.crushing_force_in_n )}
    xValue = {(d) => (d.average_compaction_pressure_in_mpa)}
    //Keine Annotations für Crushing force
    annotations = { (showTensileStrength) ? [{
      type: "y",
      tensile_strength_in_mpa: 2,
      note: {
        label: "",
        align: "left",
        lineType: null,
        wrap: 100
      },
      color: "#ffab91",
      dy: 0,
      dx: 0,
      connector: { end: "none" }
    },] : []}
    ticks = "6"
    yExtentAddition = {(min, max) => ([0, Math.ceil(max + 1)])}
    xExtentAddition = {(min, max) => ([0, Math.ceil(max + 50)])}
    {...props}
  />
};

export default compactibilityChart;
