import ActiveStorageProvider from 'react-activestorage-provider'
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { showNotification as showNotificationAction } from 'react-admin';
import { refreshView } from 'ra-core';
import url from "url";
import Dropzone from 'react-dropzone'
import Upload from '@material-ui/icons/CloudUpload';
import LinearProgress from '@material-ui/core/LinearProgress';


class UploadMeasurementCSVButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      trial: props.record,
      providerUrl: url.parse(process.env.REACT_APP_DATA_PROVIDER_URL)
    }
  }
  render() {
    const { showNotification, refreshView } = this.props;
    const { trial, providerUrl } = this.state;
    return (
      <ActiveStorageProvider
        onSubmit={ (object) =>{
          showNotification('Done!');
          refreshView()
        }
        }
        onError={ (object) =>{
          object.json().then( o => {
            showNotification(o.message, 'warning')
          }
          )
        }

        }
        endpoint={{
          host: providerUrl.hostname,
          protocol: providerUrl.protocol,
          port: providerUrl.port,
          path: providerUrl.href + "/trials/"+trial.id+"/add-measurement-csv",
          model: "trial",
          attribute: "measurement_csv_id",
          method: "PUT",
        }}

        directUploadsPath = {`${providerUrl.path}/rails/active_storage/direct_uploads`}

        headers={{ 'Authorization': 'Bearer ' + localStorage.getItem('auth_token') }}

        render={({ handleUpload, uploads, ready }) => (
          <div>
            <Dropzone
              accept=".csv, text/csv"
              onDrop={
                files => {
                  handleUpload(files)
                }
              }
            >
              {({getRootProps, getInputProps}) => (
                <section>
                  <div {...getRootProps()} style={{
                    background: '#efefef',
                    cursor: 'pointer',
                    marginTop: '1em',
                    padding: '1rem',
                    textAlign: 'center',
                    color: '#e94e1b'
                  }
                  }>
                  <input {...getInputProps()} />
                  <Upload />
                </div>
              </section>
              )}
            </Dropzone>

            {uploads.map(upload => {
              switch (upload.state) {
                case 'waiting':
                  return <LinearProgress color="primary" />
              case 'uploading':
                  return <LinearProgress color="primary" variant="determinate" value={upload.progress} />
              case 'error':
                  showNotification(upload.error, 'warning');
                  refreshView();
                  return
                case 'finished':
                  showNotification(upload.file.name + ' uploaded. Importing...');
                  return <LinearProgress color="primary" />
              }
            })}
          </div>
        )}
      />
    )
  }
}

UploadMeasurementCSVButton.propTypes = {
  push: PropTypes.func,
  record: PropTypes.object,
  showNotification: PropTypes.func,
  refreshView: PropTypes.func,
};

export default connect(null, {
  showNotification: showNotificationAction,
  refreshView: refreshView
})(UploadMeasurementCSVButton);
